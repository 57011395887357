import * as Sentry from "@sentry/vue";

export default {
  /**
   * Adds a breadcrumb to show the path of actions a user took leading up to an exception
   * @param {String} component Name of the component
   * @param {String} message Description of the action that triggered a breadcrumb, e.g. 'User authenticated'
   * @param {String} category category name, e.g. 'Auth'
   * @param {Object} data arbitrary contextual data.  Example use (passing args):
   myMethod(arg1, arg2) {
     sentry.captureBreadcrumb('Component Name', 'myMethod', 'Method Call', {arg1, arg2});
     do some other stuff
   }
   * @param {String} level optional, specify level of breadcrumb, options are:
   * "fatal", "critical", "error", "warning", "log", "info", "debug"
   * default is 'info'
   *
   * https://docs.sentry.io/platforms/javascript/guides/vue/enriching-events/breadcrumbs/
   */
  captureBreadcrumb(component, message, category, data, level = "info") {
    Sentry.addBreadcrumb({
      category,
      message,
      level,
      data: {
        component,
        ...data,
      },
    });
  },
  /**
   * Used to manually capture an exception, primarily used in 'catch' blocks
   * @param {Object} e the error object
   * https://docs.sentry.io/platforms/javascript/guides/vue/usage/#capturing-errors
   */
  captureException(e) {
    Sentry.captureException(e);
  },
  /**
   * Set arbitrary context data for enriching events
   * Generally used at init
   * https://docs.sentry.io/platforms/javascript/guides/vue/enriching-events/context/
   * @param {String} contextName name of the context, can be any string
   * @param {Object} contextObj accepts arbitrary key/value pairs. Key cannot be 'type'
   */
  setContext(contextName, contextObj) {
    Sentry.setContext(contextName, contextObj);
  },
  /**
   * Tags are indexed, searchable string pairs that can be used to search/filter events
   * https://docs.sentry.io/platforms/javascript/guides/vue/enriching-events/tags/
   * @param {String} key max length: 32 chars
   * @param {String} value max length: 200 chars
   */
  setTag(key, value) {
    Sentry.setTag(key, value);
  },
};
