import { computed, ref, watch } from "vue";
import { defineStore } from "pinia";
import { default as abTestService } from "@/api/abTests";
import type { AbTestStatus, AbTest, AbTestView } from "@/types/AbTest"; // Adjust the path as necessary
import type Site from "@/types/Site";
import type { ComputedRef } from "vue/dist/vue";
import { usePrebidStore } from "@/stores/prebid";
import { buildAbTestView } from "@/lib/flex/abTestUtils";
import { useStore } from "vuex";

export const useAbTestStore = defineStore("abTest", () => {
  // State
  const abTests = ref<AbTest[]>([]);
  const abTestsViews = ref<AbTestView[]>([]);
  const loading = ref(false);
  const error = ref<string | null>(null);
  const store = useStore();
  const accountId = computed(() => store.getters["users/accountId"]);

  const sites = computed<Site[]>(
    () => store.getters["accounts/flexSitesForAccount"]
  );

  const domains: ComputedRef<Map<number, string>> = computed(
    () => new Map(sites.value?.map((site) => [site.id, site.domain]))
  );

  const prebidStore = usePrebidStore();

  const configVersionIdNotesRecord = computed(() => {
    return Object.entries(prebidStore.currentPrebidSiteConfigsByVersion).reduce<
      Record<string, string>
    >((acc, [configId, siteConfig]) => {
      acc[configId] = siteConfig.notes;
      return acc;
    }, {});
  });

  //
  watch(accountId, fetchAccountAbTests);

  // Actions
  async function fetchAccountAbTests() {
    loading.value = true;
    error.value = null;

    try {
      const GetAbTestsResponse = await abTestService.getAccountAbTests(
        accountId.value
      );
      abTests.value = GetAbTestsResponse.abTests;

      const configVersionIds: string[] = abTests.value.map(
        (test) => test.testItems[0].pubfigConfigId
      );
      await prebidStore.getPrebidSiteConfigsByVersions(configVersionIds);

      abTestsViews.value = abTests.value.map((abTest) =>
        buildAbTestView(abTest, {
          domains: domains.value,
          prebidSiteConfigsByVersion:
            prebidStore.currentPrebidSiteConfigsByVersion,
        })
      );
    } catch (err) {
      console.error(err);
      error.value = `Failed to fetch A/B tests for account ${accountId.value}.`;
    } finally {
      loading.value = false;
    }
  }

  // Expose state, getters, and actions
  return {
    loading,
    error,
    abTests,
    abTestsViews,
    configIdNotesRecord: configVersionIdNotesRecord,
    domains,
    fetchAccountAbTests,
  };
});
