/* eslint-disable no-shadow,no-param-reassign */
import * as types from "./mutation-types";
import fadApi from "../../../api/fad";
import type { Commit } from "vuex";
import type { AxiosError } from "axios";
import { ElNotification } from "element-plus";
import type { Deployment, SiteConfig, Placement } from "@/types/Amp";

interface State {
  isLoading: number;
  deployments: Deployment[];
  siteConfigs: SiteConfig[];
  placements: Placement[];
}

const state: State = {
  isLoading: 0,
  deployments: [],
  siteConfigs: [],
  placements: [],
};

// const successHandler = (action: string, resource: string) => {
//   ElNotification({
//     title: "Info",
//     message: `${resource} ${action} successfully`,
//     type: "info",
//   });
// };

const errorHandler = (e: AxiosError<Error>, title = "") => {
  const message = e?.response?.data?.message;
  if (message) {
    ElNotification({
      title,
      message,
      type: "error",
    });
  }
};

const actions = {
  // ===== DEPLOYMENTS =====
  // This will return a list of deployments
  async getDeploymentsByAccountId(
    { commit }: { commit: Commit },
    accountId: number
  ) {
    commit(types.SET_IS_LOADING, true);
    fadApi.amp.accounts.deployments
      .getByAccountId(accountId)
      .then((deployments: Deployment) => {
        commit(types.SET_DEPLOYMENTS, deployments);
      })
      .catch((err: AxiosError<Error>) => {
        if (err?.response?.status === 404) {
          // catch silently
        } else {
          errorHandler(err, "Unable to get AMP Deployments");
        }
      })
      .finally(() => {
        commit(types.SET_IS_LOADING, false);
      });
  },
  async getSiteConfigurations(
    { commit }: { commit: Commit },
    siteIds: number[]
  ): Promise<SiteConfig[]> {
    commit(types.SET_IS_LOADING, true);
    return fadApi.amp.sites.configs
      .getAllBySiteIds(siteIds)
      .then((siteConfigurations: SiteConfig[]) => {
        commit(types.SET_AMP_SITE_CONFIGS, siteConfigurations);
        return Promise.resolve(siteConfigurations);
      })
      .catch((err: AxiosError<Error>) => {
        if (err?.response?.status === 404) {
          // catch silently
        } else {
          errorHandler(err, "Unable to get AMP Site Configurations");
        }
        return Promise.reject(err.message);
      })
      .finally(() => {
        commit(types.SET_IS_LOADING, false);
      });
  },
  // ===== AMP SITE CONFIGS =====
  // This will return a list of AMP Site configurations provided a list of site IDs
  async getAllSiteConfigsBySiteIds(
    { commit }: { commit: Commit },
    siteIds: number[]
  ) {
    commit(types.SET_IS_LOADING, true);
    fadApi.amp.sites.configs
      .getAllBySiteIds(siteIds)
      .then((siteConfigs: SiteConfig[]) => {
        commit(types.SET_AMP_SITE_CONFIGS, siteConfigs);
      })
      .catch((err: AxiosError<Error>) => {
        if (err?.response?.status === 404) {
          // catch silently
        } else {
          errorHandler(err, "Unable to get AMP Deployments");
        }
      })
      .finally(() => {
        commit(types.SET_IS_LOADING, false);
      });
  },
  async getAllPlacementsBySiteIds(
    { commit }: { commit: Commit },
    siteIds: number[]
  ) {
    commit(types.SET_IS_LOADING, true);
    fadApi.amp.sites.placements
      .getAllBySiteIds(siteIds)
      .then((placements: Placement[]) => {
        commit(types.SET_AMP_PLACEMENTS, placements);
      })
      .catch((err: AxiosError<Error>) => {
        if (err?.response?.status === 404) {
          // catch silently
        } else {
          errorHandler(err, "Unable to get AMP Deployments");
        }
      })
      .finally(() => {
        commit(types.SET_IS_LOADING, false);
      });
  },
  clear({ commit }: { commit: Commit }) {
    commit(types.SET_DEPLOYMENTS, []);
    commit(types.SET_AMP_SITE_CONFIGS, []);
  },
};

const getters = {
  isLoading: (state: State) => state.isLoading > 0,
  deployments: (state: State) => state.deployments,
  siteConfigs: (state: State) => state.siteConfigs,
  placements: (state: State) => state.placements,
};

const mutations = {
  [types.SET_IS_LOADING](state: State, increment: boolean) {
    state.isLoading += increment ? 1 : -1;
  },
  [types.SET_DEPLOYMENTS](state: State, deployments: Deployment[]) {
    state.deployments = deployments?.length ? deployments : [];
  },
  [types.SET_AMP_SITE_CONFIGS](state: State, siteConfigs: SiteConfig[]) {
    state.siteConfigs = siteConfigs?.length ? siteConfigs : [];
  },
  [types.SET_AMP_PLACEMENTS](state: State, placements: Placement[]) {
    state.placements = placements?.length ? placements : [];
  },
};

/**
 * The FAD AMP Sites Vuex module
 * @see https://vuex.vuejs.org/en/modules.html
 */
export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true,
};
