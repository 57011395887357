import { version } from "../../package.json";
/**
 * This could/should be further cleaned up
 * so we're not relying at all on the window.location.host to determine env vars
 */

const { host } = window.location;
const prodHost = "publisher.freestar.io";
const devHost = "dev-publisher.freestar.io";

const CONFIGCAT_DEV = "jWDYCDVYAEyEmbEye1k2fA/QRm6KAJMK0GEAjo0S5NIaQ";
const CONFIGCAT_PROD = "jWDYCDVYAEyEmbEye1k2fA/6g3c0PA8rUiA25v_tX4uFw";

let useEnv = true;
if ([prodHost, devHost].includes(host)) {
  useEnv = false;
}

// Local, firebase previews
const envConfig = {
  auth0: {
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    client_id: import.meta.env.VITE_AUTH0_CLIENT_ID,
    connection: import.meta.env.VITE_AUTH0_CONNECTION,
    audience: import.meta.env.VITE_AUTH0_AUDIENCE,
    callbackUrl: `${window.location.origin}/callback`,
  },
  api: {
    base_url: import.meta.env.VITE_API_BASE_URL,
    base_url_v2: import.meta.env.VITE_API_BASE_URL_V2,
    base_url_v3: import.meta.env.VITE_API_BASE_URL_V3,
    fad_base_url: import.meta.env.VITE_FAD_API_BASE_URL,
    pub_api_base_url: import.meta.env.VITE_PUB_API_BASE_URL,
    cube_api_base_url: import.meta.env.VITE_CUBE_API_BASE_URL,
  },
  app: {
    env: import.meta.env.VITE_ENV,
    release: null,
    flags: {
      report_granularity: false,
    },
  },
  sentry: {
    dsn: import.meta.env.VITE_DASHBOARD_SENTRY_DSN_PUBLIC,
    env: import.meta.env.VITE_SENTRY_ENV,
    releaseVersion: `${import.meta.env.VITE_SENTRY_RELEASE_PREFIX}@${version}`,
  },
  configcat: {
    sdk_key: import.meta.env.VITE_CONFIGCAT_SDK_KEY,
  },
  beamer: {
    product_id: "BvxRmvXz45005",
  },
  zendesk: {
    app_key: "5e70e921-293b-440e-89f5-d1b38eed96ff",
  },
  userpilot: {
    token: "STG-NX-ad46269c",
  },
};

// dev
let hostConfig = {
  auth0: {
    domain: "freestar-dev.auth0.com",
    client_id: "kJMaIeRRPfO5lNMIKR51BISXt6uUl03k",
    connection: "authorization-api",
    audience: "https://dev-api.pub.network",
    callbackUrl: `${window.location.origin}/callback`,
  },
  api: {
    base_url: "/api/v1/",
    base_url_v2: "/api/v2/",
    base_url_v3: "/api/v3/",
    fad_base_url: "/fad-api/v1/",
    pub_api_base_url: "/pub-api/v1/",
    cube_api_base_url: "/reporting-api/v1",
  },
  app: {
    env: "dev",
    release: null,
    flags: {
      report_granularity: false,
    },
  },
  sentry: {
    dsn: "https://902e4e2c277f4073b1f3159ba905d9e4@o90274.ingest.sentry.io/5886568",
    env: "dev",
    releaseVersion: `dashboard@${version}`,
  },
  configcat: {
    sdk_key: CONFIGCAT_DEV,
  },
  beamer: {
    product_id: "BvxRmvXz45005",
  },
  liveChat: {
    license: "14293899",
  },
  zendesk: {
    app_key: "5e70e921-293b-440e-89f5-d1b38eed96ff",
  },
  userpilot: {
    token: "STG-NX-ad46269c",
  },
};

// prod
if (prodHost === host) {
  hostConfig = {
    auth0: {
      domain: "login.freestar.io",
      client_id: "B14hQZ84HaFwU5J6ETBgf2aZYaWnfG9P",
      connection: "authorization-api",
      audience: "https://api.pub.network",
      callbackUrl: `${window.location.origin}/callback`,
    },
    api: {
      base_url: "/api/v1/",
      base_url_v2: "/api/v2/",
      base_url_v3: "/api/v3/",
      fad_base_url: "/fad-api/v1/",
      pub_api_base_url: "/pub-api/v1/",
      cube_api_base_url: "/reporting-api/v1",
    },
    app: {
      env: "prod",
      release: null,
      flags: {
        report_granularity: false,
      },
    },
    sentry: {
      dsn: "https://902e4e2c277f4073b1f3159ba905d9e4@o90274.ingest.sentry.io/5886568",
      env: "prod",
      releaseVersion: `dashboard@${version}`,
    },
    configcat: {
      sdk_key: CONFIGCAT_PROD,
    },
    beamer: {
      product_id: "BvxRmvXz45005",
    },
    liveChat: {
      license: "14293899",
    },
    zendesk: {
      app_key: "5e70e921-293b-440e-89f5-d1b38eed96ff",
    },
    userpilot: {
      token: "NX-ad46269c",
    },
  };
}

const config = useEnv ? envConfig : hostConfig;

export default config;
