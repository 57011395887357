import config from "@/config/config";
import analyticsUserData from "@/lib/helpers/analyticsUserData";

interface Metadata {
  [key: string]: string | number | boolean | string[] | null;
}

interface Pendo {
  initialize(options?: Record<string, any>): void;
  track(eventName: string, data?: Metadata): void;
  clearSession(): void;
  teardown(): void;
  isReady(): boolean;
  validateInstall(): void;
}

declare global {
  interface Window {
    pendo: Pendo;
  }
}

// https://support.pendo.io/hc/en-us/articles/360031862272-Install-Pendo-on-a-single-page-web-application
export async function pendoInit() {
  if (
    window.pendo &&
    typeof window.pendo.isReady === "function" &&
    !window.pendo.isReady() &&
    config.app.env === "prod"
  ) {
    // only init Pendo in prod, if it exsits and is not ready
    const { user, account } = await analyticsUserData.getUserAndAccountData();

    window.pendo.initialize({
      visitor: {
        id: user.id,
        email: user.email,
        role: user.type,
      },
      account: {
        id: account.id,
        name: account.name,
      },
    });
  } else {
    console.warn("Pendo not loaded, not initializing pendo");
  }
}

// https://support.pendo.io/hc/en-us/articles/360032294291-Configure-Track-Events
export function pendoEvent(eventName: string, data: Metadata) {
  if (
    window.pendo &&
    typeof window.pendo.isReady === "function" &&
    window.pendo.isReady()
  ) {
    window.pendo.track(eventName, data);
  } else {
    console.warn("Pendo not loaded, not tracking event", eventName);
  }
}

// clearSession() and teardown() are not well documented
export function pendoDisable() {
  if (
    window.pendo &&
    typeof window.pendo.isReady === "function" &&
    window.pendo.isReady()
  ) {
    // clear current visitor and account data
    window.pendo.clearSession();
    // stop tracking until next initialization
    window.pendo.teardown();
  } else {
    console.warn("Pendo not loaded, not disabling");
  }
}
