import { pendoInit } from "@/lib/helpers/pendo";
import type { NavigationHookAfter } from "vue-router";
const pendoInitAfterHook: NavigationHookAfter = async (to) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Page requires auth (basically not login or reset-password)
    await pendoInit();
  }
};

export default pendoInitAfterHook;
