import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import store from "../../store";

export default function flexRedirect(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const userRoles = store.getters["users/roles"];
  const isFlexUser =
    userRoles.includes("flex_admin") || userRoles.includes("flex_viewer");
  const isAdmin = userRoles.includes("administrator");

  const isFlexPath = to.path.startsWith("/flex");
  const targetFlexPath = to.fullPath.replace(/^\/(?!flex\/)/, "/flex/");
  const targetNonFlexPath = to.fullPath.replace(/^\/flex\//, "/");

  // Exception for specific paths like /logout
  const nonRedirectPaths = [
    "/co-fallback",
    "/callback",
    "/createCallback",
    "/logout",
    "/login",
    "/reset-password",
    "/reset-password/complete",
    "/onboarding",
  ];
  if (nonRedirectPaths.includes(to.path)) {
    return next();
  }

  // Redirect specific paths to the Flex dashboard for Flex users only
  const flexDashboardPaths = ["/my-documents", "/site-config"];
  if (
    isFlexUser &&
    flexDashboardPaths.some((path) => to.path.startsWith(path))
  ) {
    return next({ path: "/flex/dashboard" });
  }

  // Reverse: Redirect specific paths to the non-Flex dashboard for non-Flex users only
  const nonFlexDashboardPaths = [
    "/flex/site-config/manage-users",
    "/flex/marketplace",
  ];
  if (
    !isFlexUser &&
    nonFlexDashboardPaths.some((path) => to.path.startsWith(path))
  ) {
    return next({ path: "/dashboard" });
  }

  // Redirect /payments to /flex/billing for Flex users only
  if (isAdmin && isFlexUser && to.path === "/payments") {
    return next({ path: "/flex/billing" });
  }

  // Reverse Redirect: Redirect /flex/billing to /payments for non-Flex users
  if (!isFlexUser && to.path === "/flex/billing") {
    return next({ path: "/payments" });
  }

  // Handle root path specifically for Flex users
  if (isFlexUser && to.path === "/") {
    return next({ path: "/flex/dashboard" });
  }

  // Redirect Flex users to Flex paths
  if (isFlexUser && !isFlexPath) {
    return next({ path: targetFlexPath });
  }

  // Redirect non-Flex users to non-Flex paths
  if (!isFlexUser && isFlexPath) {
    return next({ path: targetNonFlexPath });
  }

  // Default: Allow navigation
  next();
}
