import { checkToken } from "@/api/tokens";
import Client from "@/api/client";
import config from "@/config/config";
import sentry from "@/lib/helpers/sentry";

let fadClient;
/**
 * Lazy-loaded method to get an API client at runtime
 * @return {client.ApiClient}
 */
const getClient = (authRequired = true) =>
  new Promise((resolve) => {
    if (authRequired) {
      checkToken().then(() => {
        fadClient = new Client.ApiClient(config.api.fad_base_url);
        // fadClient = new Client.ApiClient("http://127.0.0.1:8090/api/v1");
        resolve(fadClient);
      });
    } else {
      fadClient = new Client.ApiClient(config.api.fad_base_url);
      // fadClient = new Client.ApiClient("http://127.0.0.1:8090/api/v1");
      resolve(fadClient);
    }
  });

const captureBreadcrumb = (name, data) => {
  sentry.captureBreadcrumb({
    message: name,
    category: "API Call",
    data: {
      API: "fadApi",
      data,
    },
  });
};

export default {
  amp: {
    accounts: {
      deployments: {
        getByAccountId(accountId) {
          captureBreadcrumb("amp.accounts.deployments.getByAccountId()");
          return getClient()
            .then((client) =>
              client.get(`amp/accounts/${accountId}/deployments`)
            )
            .then((response) => Promise.resolve(response.data))
            .catch((error) => Promise.reject(error));
        },
      },
    },
    sites: {
      configs: {
        getAllBySiteIds(siteIds) {
          return getClient()
            .then((client) =>
              client.get("amp/sites/configurations", {
                params: { "site-ids": siteIds.join(",") },
              })
            )
            .then((res) => Promise.resolve(res.data.items))
            .catch((error) => Promise.reject(error));
        },
      },
      placements: {
        getAllBySiteIds(siteIds) {
          return getClient()
            .then((client) =>
              client.get("amp/sites/placements", {
                params: { "site-ids": siteIds.join(",") },
              })
            )
            .then((res) => Promise.resolve(res.data.items))
            .catch((error) => Promise.reject(error));
        },
      },
    },
  },
};
