import AbstractRestService from "@/api/abstractRestService";

import config from "../config/config";
import type { GetAbTestsResponse } from "@/types/AbTest";

export interface AbTestService {
  getAccountAbTests(accountId: number): Promise<GetAbTestsResponse>;
  getSiteAbTests(siteId: number): Promise<GetAbTestsResponse>;
}

export class DefaultAbTestService
  extends AbstractRestService
  implements AbTestService
{
  /**
   * Init
   * @param authRequired
   */
  constructor(authRequired?: boolean) {
    super(config.api.base_url, authRequired);
  }

  async getAccountAbTests(accountId: number): Promise<GetAbTestsResponse> {
    try {
      const client = await this.getRestClient();
      const response = await client.get(
        `pubfig/flex/abTest?accountId=${accountId}`
      );
      return response.data;
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async getSiteAbTests(siteId: number): Promise<GetAbTestsResponse> {
    try {
      const client = await this.getRestClient();
      const response = await client.get(`pubfig/flex/abTest?siteId=${siteId}`);
      return response.data;
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async createAbTest(testData: any) {
    const client = await this.getRestClient(config.api.base_url_v3);
    const response = await client.post("pubfig/abTest", testData);
    return response.data;
  }
}

export default new DefaultAbTestService();
