import { inject } from "vue";
import type { Emitter } from "mitt";
import type { CurrentUserData, ImpersonatedUser } from "@/types/Impersonation";

/**
 * Custom events
 */
export type Events = {
  onImpresonate: ImpersonatedUser;
  onRemove: CurrentUserData;
};

/**
 * Get event emitter instance composable
 *
 * @author diego.marafetti
 * @since 1.0.0
 */
export default function useEventEmitter() {
  const emitter = inject<Emitter<Events>>("$emitter");

  if (typeof emitter === "undefined")
    throw new Error("Event emitter has not been registered!");

  return { emitter };
}
