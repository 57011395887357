import Client from "./client";
import config from "../config/config";
import { checkToken } from "./tokens";

let placementsClient;

/**
 * Lazy-loaded method to get an API client at runtime
 * @return {client.ApiClient}
 */
const getClientV2 = (authRequired = true) =>
  new Promise((resolve) => {
    if (authRequired) {
      checkToken().then(() => {
        placementsClient = new Client.ApiClient(config.api.base_url_v2);
        // placementsClient = new Client.ApiClient('http://127.0.0.1:3007/api/v2/');
        resolve(placementsClient);
      });
    } else {
      placementsClient = new Client.ApiClient(config.api.base_url_v2);
      // placementsClient = new Client.ApiClient('http://127.0.0.1:3007/api/v2/');
      resolve(placementsClient);
    }
  });

/**
 * Lazy-loaded method to get an API client at runtime
 * @return {client.ApiClient}
 */
const getClientV3 = (authRequired = true) =>
  new Promise((resolve) => {
    if (authRequired) {
      checkToken().then(() => {
        placementsClient = new Client.ApiClient(config.api.base_url_v3);
        resolve(placementsClient);
      });
    } else {
      placementsClient = new Client.ApiClient(config.api.base_url_v3);
      resolve(placementsClient);
    }
  });

export default {
  /**
   * Get a list of placement names and IDs for given site IDs
   * Used to generate filter options for advanced reports
   *
   * @param Array siteIds
   */
  getPlacementNames(siteIds) {
    const siteIdParam = siteIds.join(",");
    return getClientV2()
      .then((client) =>
        client.get(`prebid/placementnames?siteIds=${siteIdParam}`)
      )
      .then((response) => Promise.resolve(response.data.placementData))
      .catch((error) => Promise.reject(error));
  },

  getPlacements(accountId, siteId) {
    return getClientV3()
      .then((client) =>
        client.get(`placements?accountId=${accountId}&siteId=${siteId}`)
      )
      .then((response) => Promise.resolve(response.data.placements || []))
      .catch((error) => Promise.reject(error));
  },

  getPlacement(id) {
    return getClientV3()
      .then((client) => client.get(`placements/flex/${id}`))
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
};
