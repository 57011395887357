import decode from "jwt-decode";
import objectHasProperty from "./objectHasProperty";

export function getTokenExpirationDate(token) {
  const decoded = decode(token);
  if (!decoded.exp) {
    return null;
  }

  const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
  date.setUTCSeconds(decoded.exp);
  return date;
}

export function isTokenExpired(token) {
  const date = getTokenExpirationDate(token);
  if (date === null) {
    return false;
  }
  return !(date.valueOf() > new Date().valueOf());
}

export function getIdTokenProp(token, key = null, defaultValue = undefined) {
  const decoded = decode(token);

  if (key === null || !objectHasProperty(decoded, key)) {
    return defaultValue;
  }

  return decoded[key];
}

export function getMetadata(token, key = null, defaultValue = undefined) {
  const decoded = decode(token);
  const prop = "https://api.pub.network/app_metadata";

  if (!objectHasProperty(decoded, prop)) {
    return {};
  }

  if (key === null) {
    return decoded[prop];
  } else if (!objectHasProperty(decoded[prop], key)) {
    return defaultValue;
  }

  return decoded[prop][key];
}
