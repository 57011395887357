import client from "./client";
import config from "../config/config";
import { checkToken } from "./tokens";

let tfaClient;

/**
 * Lazy-loaded method to get an API client at runtime
 * @return {client.ApiClient}
 */
const getClient = (authRequired = true) => {
  return new Promise((resolve) => {
    if (authRequired) {
      checkToken().then(() => {
        tfaClient = new client.ApiClient(config.api.base_url);
        resolve(tfaClient);
      });
    } else {
      tfaClient = new client.ApiClient(config.api.base_url);
      resolve(tfaClient);
    }
  });
};

export default {
  genTotp(data) {
    return getClient(false)
      .then((client) => client.post("/authorization/public/totp/gen", data))
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  validateTotp(data) {
    return getClient(false)
      .then((client) =>
        client.post("/authorization/public/totp/validate", data)
      )
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  encryptCookie(data) {
    return getClient(false)
      .then((client) =>
        client.post("/authorization/public/totp/encryptCookie", data, {
          headers: { "Content-Type": "text/plain" },
        })
      )
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  validateCookie(data) {
    return getClient(false)
      .then((client) =>
        client.post("/authorization/public/totp/validateCookie", data)
      )
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
};
