export const UPDATE_BREAKDOWN_MEASURES = "UPDATE_BREAKDOWN_MEASURES";
export const UPDATE_BREAKDOWN_DIMENSIONS = "UPDATE_BREAKDOWN_DIMENSIONS";
export const UPDATE_QUERY = "UPDATE_QUERY";
export const UPDATE_MEASURES = "UPDATE_MEASURES";
export const UPDATE_DIMENSIONS = "UPDATE_DIMENSIONS";
export const UPDATE_INVENTORY = "UPDATE_INVENTORY";
export const UPDATE_DATE_RANGE = "UPDATE_DATE_RANGE";
export const UPDATE_CUSTOM_DATE_RANGE = "UPDATE_CUSTOM_DATE_RANGE";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_ACTIVE_DASHBOARD = "SET_ACTIVE_DASHBOARD";
export const SET_ALL_DASHBOARDS = "SET_ALL_DASHBOARDS";
export const REMOVE_ALL_DASHBOARD_TILES = "REMOVE_ALL_DASHBOARD_TILES";
export const RECEIVE_DASHBOARD = "RECEIVE_DASHBOARD";
export const UPDATE_OVERVIEW_SELECTION = "UPDATE_OVERVIEW_SELECTION";
export const UPDATE_OVERVIEW_METRICS = "UPDATE_OVERVIEW_METRICS";
export const UPDATE_OVERVIEW_DATERANGE = "UPDATE_OVERVIEW_DATERANGE";
export const UPDATE_CHART_TYPE = "UPDATE_CHART_TYPE";
export const UPDATE_TILE_TITLE = "UPDATE_TILE_TITLE";
export const NEW_DASHBOARD_TILE = "NEW_DASHBOARD_TILE";
export const REMOVE_DASHBOARD_TILE = "REMOVE_DASHBOARD_TILE";
export const UPDATE_DASHBOARD_TILE = "UPDATE_DASHBOARD_TILE";
export const UPDATE_ALL_TILES_DATE_RANGE = "UPDATE_ALL_TILES_DATE_RANGE";
export const UPDATE_TABLE_PAGE_SIZE = "UPDATE_TABLE_PAGE_SIZE";
export const UPDATE_ALL_TILES_INVENTORY = "UPDATE_ALL_TILES_INVENTORY";
export const UPDATE_SELECTED_INVENTORY = "UPDATE_SELECTED_INVENTORY";
export const SET_COMPARISON_STATUS = "SET_COMPARISON_STATUS";
export const UPDATE_COMPARISON_METRICS = "UPDATE_COMPARISON_METRICS";
export const UPDATE_OVERVIEW_CUSTOM_DATE_RANGE =
  "UPDATE_OVERVIEW_CUSTOM_DATE_RANGE";
export const UPDATE_TILE_CUSTOM_DATE_RANGE_AND_DATE_RANGE =
  "UPDATE_TILE_CUSTOM_DATE_RANGE_AND_DATE_RANGE";
