/* eslint-disable no-shadow,no-param-reassign */
import * as types from "./mutation-types";
import auth from "../../../api/auth0";
import {
  getIdTokenProp,
  getMetadata,
  isTokenExpired,
} from "../../../lib/helpers/jwt-helper";
import usersAPI from "../../../api/users";
import raven from "../../../lib/helpers/raven";
import { UsersStore } from "@/stores/user";
import intersection from "lodash/intersection";
import { storeToRefs } from "pinia";

const state = {
  isAuthenticating: false,
  idToken: null,
  accessToken: null,
  refreshToken: null,
  username: null,
  staffId: null,
  email: null,
  emailFromToken: null,
  profile: null,
  error: null,
  intendedRoute: null,
  impersonation_accounts: [],
  impersonation: false,
  users: [],
  allUsersLoaded: false,
  currentAccount: null,
};

const getters = {
  isAuthenticated: (state) => typeof state.idToken === "string",
  isTokenExpired: (state) =>
    (!!state.idToken && isTokenExpired(state.idToken)) ||
    (!!state.accessToken && isTokenExpired(state.accessToken)),
  isAuthenticating: (state) => state.isAuthenticating,
  userId: (state) => {
    if (state.profile) {
      const userId = state.profile.sub;
      return userId ? userId.split("|")[1] : null;
    }
    return null;
  },
  currentAccount: (state) => state.currentAccount,
  idToken: (state) => state.idToken,
  accessToken: (state) => state.accessToken,
  getError: (state) => state.error,
  hasIntendedRoute: (state) => state.intendedRoute !== null,
  getIntendedRoute: (state) => state.intendedRoute,
  profile: (state) => state.profile,
  accountId: (state) => {
    if (state.currentAccount) {
      return state.currentAccount;
    }
    const accounts = getters.accounts(state);
    if (accounts.length > 0) {
      return accounts[0];
    }
    return undefined;
  },
  roles: (state) => {
    if (!state.accessToken) {
      return [];
    }

    // Retrieve the roles array from the access token.
    const decodedRoles = getMetadata(state.accessToken, "roles", []);

    const { impersonating, impersonatedProfile, impersonatingFlexUsers } =
      storeToRefs(UsersStore());

    // user impersonation
    // keeps admin role for non admins
    if (impersonating.value) {
      // load new user roles instead
      const impersonationRoles = [...impersonatedProfile.value.roles];
      if (!impersonatedProfile.value.isAdmin) {
        impersonationRoles.push("administrator");
      }

      // TODO remove this when both UIs are merged
      // Flex accounts: For fully-managed accounts, when we are
      // impersonating a flex user, do not include the flex role for now
      if (
        !decodedRoles.includes("flex_admin") &&
        !decodedRoles.includes("flex_viewer")
      ) {
        let index = -1;
        index = impersonationRoles.indexOf("flex_admin");
        if (index !== -1) {
          impersonationRoles.splice(index, 1);
        }
        index = impersonationRoles.indexOf("flex_viewer");
        if (index !== -1) {
          impersonationRoles.splice(index, 1);
        }
      } else {
        // for flex accounts, when we are impersonating fully-managed users
        // we need to include a flex role. Otherwise, the ui will break
        impersonationRoles.push("flex_admin");
      }

      return impersonationRoles;
    }

    // If the user is an administrator, they may be accessing a non-managed account,
    // which means they could be impersonating a different role.
    // https://freestar.atlassian.net/browse/BITOPS-8662
    // Flex account impersonation
    // return the roles array + flex roles
    // For Flex: ["administrator", ... , "flex_admin"]
    if (impersonatingFlexUsers.value) {
      return decodedRoles.concat(["flex_admin"]);
    }

    // untouched roles array
    return decodedRoles;
  },
  companies: (state) => {
    if (!state.accessToken) {
      return [];
    }

    return getMetadata(state.accessToken, "companies", []);
  },
  sites: (state) => {
    if (!state.accessToken) {
      return [];
    }

    return getMetadata(state.accessToken, "sites", []);
  },
  staffId: (state) => {
    if (state && state.staffId) {
      return state.staffId;
    }
    if (state && state.accessToken) {
      return getMetadata(state.accessToken, "staff_id", "");
    }
    return "";
  },
  email: (state) => {
    if (state.email) {
      return state.email;
    }
    if (state.accessToken) {
      return getMetadata(state.accessToken, "email", "");
    }
    return [];
  },
  username: (state) => {
    if (!state.idToken) {
      return "";
    }

    return getIdTokenProp(state.idToken, "name", "");
  },
  accounts: (state) => {
    if (
      getters.isAdmin(state) &&
      state.impersonation &&
      Array.isArray(state.impersonation_accounts)
    ) {
      return state.impersonation_accounts;
    }

    if (!state.accessToken) {
      return [];
    }

    return getMetadata(state.accessToken, "accounts", []);
  },
  isImpersonation: (state) => state.impersonation,
  isAdmin: (state) => getters.roles(state).indexOf("administrator") !== -1,
  isPublisher: (state) => getters.roles(state).indexOf("publisher") !== -1,
  isAdRecoveryStandalone: (state) =>
    getters.roles(state).indexOf("fsr_standalone") !== -1,
  isPublisherSiteConfigUser: (state) =>
    getters.roles(state).indexOf("publisher_site_config_user") !== -1,
  isAccountingAllCompaniesRead: (state) =>
    getters.roles(state).indexOf("accounting_all_companies_read") !== -1,
  isFlexUser: (state) => {
    const userRoles = getters.roles(state);
    return intersection(["flex_admin", "flex_viewer"], userRoles).length > 0;
  },
  isFlexUserAdmin: (state) => getters.roles(state).indexOf("flex_admin") !== -1,
};

const actions = {
  signIn({ commit }, options) {
    // Don't capture options here, it includes the user's password
    raven.captureBreadcrumb("Login", "signIn()", "VueX Action", {
      username: options.username,
    });
    commit(types.SIGN_IN_REQUEST);

    return new Promise((resolve, reject) => {
      auth.login(options, (err) => {
        if (err) {
          raven.captureException(err);
          commit(types.SIGN_IN_FAILURE, {
            error: {
              code: err.code,
              name: err.name,
              message: err.description,
            },
          });
          return reject(err);
        }
      });
    });
  },

  signOut({ commit }) {
    commit(types.SIGN_OUT);
    return Promise.resolve();
  },

  clearError({ commit }) {
    commit(types.CLEAR_SIGN_IN_FAILURE);
  },

  logError({ commit }, err) {
    commit(types.SIGN_IN_FAILURE, {
      error: {
        code: err ? err.code : 0,
        name: err ? err.name : "",
        message: err ? err.description : "",
      },
    });
  },

  authParseHash({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      auth.parseHash((err, authResult) => {
        const authData = authResult;
        if (err) {
          return dispatch("logError", {
            code: null,
            name: null,
            description: err.errorDescription,
          })
            .then(reject)
            .catch(reject);
        }

        if (authData && authData.accessToken && authData.idToken) {
          authData.roles = getMetadata(authData.accessToken, "roles", []);
          authData.staffId = getMetadata(authData.accessToken, "staff_id", "");
          authData.email = getMetadata(authData.accessToken, "email", "");
          authData.sites = getMetadata(authData.accessToken, "sites", []);
          authData.companies = getMetadata(
            authData.accessToken,
            "companies",
            []
          );
          commit(types.PARSE_HASH_SUCCESS, authData);
          return dispatch("getProfile").then(resolve).catch(reject);
        }

        return reject();
      });
    });
  },

  refreshAuth({ commit, dispatch }, authData) {
    return new Promise((resolve, reject) => {
      if (authData && authData.accessToken && authData.idToken) {
        authData.roles = getMetadata(authData.accessToken, "roles", []);
        authData.staffId = getMetadata(authData.accessToken, "staff_id", []);
        authData.email = getMetadata(authData.accessToken, "email", []);
        authData.sites = getMetadata(authData.accessToken, "sites", []);
        authData.companies = getMetadata(authData.accessToken, "companies", []);
        commit(types.REFRESH_TOKEN, authData);
        return dispatch("getProfile").then(resolve).catch(reject);
      }
      return reject();
    });
  },

  setIntendedRoute({ commit }, route) {
    localStorage.setItem("fs_route", route.path);
    commit(types.SET_INTENDED_ROUTE, route);
  },

  clearIntendedRoute({ commit }) {
    localStorage.setItem("fs_route", "");
    commit(types.CLEAR_INTENDED_ROUTE, null);
  },

  setImpersonation({ commit }, accounts) {
    if (getters.isAdmin) {
      commit(types.SET_IMPERSONATION, accounts);
      // Fix for old tile data displaying when changing account on a page other than the dashboard
      commit("profiles/CLEAR_APIDATA", null, { root: true });
      commit("tiles/CLEAR_ALL", null, { root: true });
    }
  },

  clearImpersonation({ commit }) {
    commit(types.CLEAR_IMPERSONATION, null);
  },

  setCurrentAccount({ commit }, accountId) {
    if (getters.isAdmin) {
      commit(types.SET_CURRENT_ACCOUNT, accountId);
    }
  },

  getProfile({ commit, state }) {
    return new Promise((resolve, reject) => {
      // Skip if the profile already exists
      if (state.profile) {
        return resolve();
      }

      if (!state.accessToken) {
        return reject(new Error("The user does not have an accessToken"));
      }

      return auth.client.userInfo(state.accessToken, (error, profile) => {
        if (error) {
          // @todo error message?
          return reject(error);
        }
        commit(types.GET_PROFILE_SUCCESS, { profile });
        return resolve();
      });
    });
  },
  getEmailFromToken({ commit, state }) {
    if (state.emailFromToken) {
      return Promise.resolve(state.emailFromToken);
    }
    return new Promise((resolve, reject) => {
      usersAPI
        .getEmailAddress()
        .then((response) => {
          commit(types.GET_EMAIL_SUCCESS, response.email);
          resolve(response.email);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getAll({ commit, state }) {
    if (state.users.length) {
      return Promise.resolve(state.users);
    }
    return new Promise((resolve, reject) => {
      usersAPI
        .getUsers()
        .then((response) => {
          commit(types.RECEIVE_USERS, { users: response.profiles });
          resolve(response.profiles);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [types.ALL_LOADED](state, loaded) {
    state.allUsersLoaded = loaded;
  },

  [types.SIGN_IN_REQUEST](state) {
    state.isAuthenticating = true;
  },

  [types.SIGN_IN_FAILURE](state, { error }) {
    state.error = error;
    state.isAuthenticating = false;
  },

  [types.CLEAR_SIGN_IN_FAILURE](state) {
    state.error = null;
  },

  [types.GET_EMAIL_SUCCESS](state, email) {
    state.emailFromToken = email;
  },

  [types.SIGN_OUT](state) {
    state.idToken = null;
    state.accessToken = null;
    state.refreshToken = null;
    state.username = null;
    state.staffId = null;
    state.email = null;
    state.profile = null;
    state.impersonation_accounts = [];
    state.impersonation = false;
  },

  [types.PARSE_HASH_SUCCESS](state, authData) {
    state.idToken = authData.idToken;
    state.accessToken = authData.accessToken;
    state.refreshToken = authData.refreshToken;
    state.username = getters.username(state);
    state.staffId = getters.staffId(state);
    state.profileId = authData.profileId;
    state.email = getters.email(state);
    state.error = null;
    state.isAuthenticating = false;
    state.impersonation_accounts = [];
    state.impersonation = false;
  },

  [types.SIGN_IN_SUCCESS](state, { authData }) {
    state.idToken = authData.idToken;
    state.accessToken = authData.accessToken;
    state.refreshToken = authData.refreshToken;
    state.username = getters.username(state);
    state.staffId = getters.staffId(state);
    state.email = getters.email(state);
    state.error = null;
    state.isAuthenticating = false;
    state.impersonation_accounts = [];
    state.impersonation = false;
  },

  [types.REFRESH_TOKEN](state, authData) {
    state.idToken = authData.idToken;
    state.accessToken = authData.accessToken;
    state.refreshToken = authData.refreshToken;
    state.username = getters.username(state);
    state.staffId = getters.staffId(state);
    state.email = getters.email(state);
    state.isAuthenticating = false;
  },

  [types.GET_PROFILE_SUCCESS](state, { profile }) {
    state.profile = profile;
    state.error = null;
  },

  [types.SET_INTENDED_ROUTE](state, route) {
    state.intendedRoute = route.path;
  },

  [types.CLEAR_INTENDED_ROUTE](state) {
    state.intendedRoute = null;
  },

  [types.SET_IMPERSONATION](state, accounts) {
    state.impersonation = true;
    state.impersonation_accounts = accounts;
    if (!Array.isArray(state.impersonation_accounts)) {
      state.impersonation_accounts = [state.impersonation_accounts];
    }
  },

  [types.CLEAR_IMPERSONATION](state) {
    state.impersonation = false;
    state.impersonation_accounts = [];
  },

  [types.SET_CURRENT_ACCOUNT](state, accountId) {
    state.currentAccount = accountId;
  },
};

/**
 * The users vuex module
 * @see https://vuex.vuejs.org/en/modules.html
 */
export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
