import { defineStore } from "pinia";
import { computed, watch } from "vue";
import store from "@/store";
import { default as zendeskTokenService } from "@/api/zendesk"; // Import your token service

const zendeskChatWidgetToken = "zendesk_chat_widget_token";
export const useZendeskTokenStore = defineStore("zendeskToken", {
  state: () => ({
    zendeskToken: localStorage.getItem(zendeskChatWidgetToken), // The Zendesk token
  }),

  actions: {
    persistToLocalStorage() {
      if (this.zendeskToken) {
        localStorage.setItem(zendeskChatWidgetToken, this.zendeskToken);
      } else {
        localStorage.removeItem(zendeskChatWidgetToken);
      }
    },
    async fetchZendeskToken() {
      try {
        this.zendeskToken = await zendeskTokenService.getZendeskToken(); // Automatically updates the state
        this.persistToLocalStorage();
      } catch (error) {
        console.error("Error fetching Zendesk token:", error);
      }
    },

    clearZendeskToken() {
      this.zendeskToken = null; // Clears the token from the state
      this.persistToLocalStorage();
    },

    fetchZendeskTokenWhenUserLogIn(
      loginCallback: (token: string) => void,
      logoutCallback: () => void
    ) {
      watch(
        computed(() => store.getters["users/isAuthenticated"]),
        async (isAuthenticated) => {
          if (isAuthenticated) {
            //Restricts for Flex users only for now
            const isFlexUser = store.getters["users/isFlexUser"];
            if (!isFlexUser) return;

            if (this.zendeskToken == null) {
              await this.fetchZendeskToken(); // Fetch token when logged in
              if (this.zendeskToken) {
                loginCallback(this.zendeskToken); // Call the callback with the token
              }
            } else {
              loginCallback(this.zendeskToken); // Call the callback immediately if token is already available
            }
          } else {
            if (this.zendeskToken != null) {
              this.clearZendeskToken(); // Clear token when logged out
              logoutCallback();
            }
          }
        },
        { immediate: true } // Run immediately if already logged in
      );
    },
  },
});
