import { createApp } from "vue";
import { createPinia } from "pinia";
import ElementPlus from "element-plus";
import VueClickAway from "vue3-click-away";
import "element-plus/dist/index.css";
import Hotjar from "@hotjar/browser";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import config from "./config/config";

import * as Sentry from "@sentry/vue";

import "@mdi/font/css/materialdesignicons.css";

import "./assets/main.css";
import "./sass/app.scss";
import "./sass/overrides.scss";

// Flex styles
import "@/sass/flex/styles.scss";

// Vuetify
import vuetify from "./plugins/vuetify";

// Zendesk
import zendesk from "@/plugins/zendesk";

// Event emitter
import emitter from "@/plugins/emitter";

// Userpilot
import { userpilotInit } from "@/lib/helpers/userpilot";

console.log(
  `Freestar Publisher Dashboard Version: ${config.sentry.releaseVersion}`
);

const app = createApp(App);
app.config.performance = true;

// Userpilot
userpilotInit();

app.use(createPinia());
app.use(router);
app.use(store);
app.use(ElementPlus);
app.use(VueClickAway);
app.use(vuetify);
app.use(emitter);
app.use(zendesk, {
  id: "ze-snippet",
  src: "https://static.zdassets.com/ekr/snippet.js",
});

if (config.app.env !== "local") {
  Sentry.init({
    app,
    dsn: "https://aa848daa48284128b0140830d29b4cc4@o90274.ingest.sentry.io/4505245554507776",
    environment: config.app.env,
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          "localhost",
          /^https:\/\/pub-dashboard-api\.pub\.network\/pub-api/,
          /^https:\/\/dev-pub-dashboard-api\.pub\.network\/pub-api/,
        ],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

if (config.app.env === "prod") {
  const siteId = 3437879;
  const hotjarVersion = 6;
  Hotjar.init(siteId, hotjarVersion);
}

app.mount("#app");
