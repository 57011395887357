/* eslint-disable no-shadow,no-param-reassign */
import find from "lodash/find";
import * as types from "./mutation-types";
import networksApi from "../../../api/networks";

function copy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

/*
allActive / getAllActive should be used for most purposes

*/

const state = {
  allActive: [],
  all: [],
  loaded: false,
  loading: false,
  /**
   * Network prebid params
   * @type {Object}
   */
  params: {},
  ampParams: {},
  statusList: [],
  statusDetails: {},
};

const getters = {
  allActive: (state) => state.allActive,
  all: (state) => state.all,
  amp: (state) => state.all.filter((n) => n.ampEnabled),
  loaded: (state) => state.loaded,
  loading: (state) => state.loading,
  networkById: (state) => (id) =>
    find(state.allActive, { id }) || find(state.all, { id }),
  statusList: (state) => state.statusList,
  statusDetails: (state) => (id) => state.statusDetails[id],
  prebidClientEnabled: (state) =>
    state.allActive.filter((n) => n.prebidClientEnabled),
};

const actions = {
  getAllActive({ commit }) {
    commit(types.FETCHING_AD_NETWORKS);

    return networksApi.allActive().then((response) => {
      const { networks } = response;
      const params = {};
      const ampParams = {};
      Object.keys(networks).forEach((network) => {
        params[networks[network].slug] = networks[network].params || {};
        ampParams[networks[network].slug] = networks[network].ampParams || {};
      });
      commit(types.RECEIVE_AD_NETWORKS, { networks, params, ampParams });
      commit(types.FINISHED_FETCHING_AD_NETWORKS);
      return Promise.resolve(copy(networks));
    });
  },
  getAll({ commit }) {
    commit(types.FETCHING_AD_NETWORKS);

    return networksApi.all().then((response) => {
      const { networks } = response;
      const params = {};
      const ampParams = {};
      Object.keys(networks).forEach((network) => {
        params[networks[network].slug] = networks[network].params || {};
        ampParams[networks[network].slug] = networks[network].ampParams || {};
      });

      commit(types.RECEIVE_ALL_AD_NETWORKS, { networks, params, ampParams });
      commit(types.FINISHED_FETCHING_AD_NETWORKS);
      return Promise.resolve(copy(networks));
    });
  },
};

const mutations = {
  [types.RECEIVE_AD_NETWORKS](state, { networks, params, ampParams }) {
    state.loaded = true;
    state.allActive = networks;
    state.params = params;
    state.ampParams = ampParams;
  },
  [types.RECEIVE_ALL_AD_NETWORKS](state, { networks, params, ampParams }) {
    state.loaded = true;
    state.all = networks;
    state.params = params;
    state.ampParams = ampParams;
  },
  [types.FETCHING_AD_NETWORKS](state) {
    state.loading = true;
  },
  [types.FINISHED_FETCHING_AD_NETWORKS](state) {
    state.loading = false;
  },
};

/**
 * The networks Vuex module
 * @see https://vuex.vuejs.org/en/modules.html
 */
export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
