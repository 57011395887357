import store from "../store";
import raven from "../lib/helpers/raven";
import auth from "./auth0";
import config from "../config/config";
import { isTokenExpired } from "../lib/helpers/jwt-helper";

const refreshToken = () => {
  raven.captureBreadcrumb("axios", "refreshToken", "api", {});
  return new Promise((resolve, reject) => {
    auth.checkSession(
      {
        redirectUri: config.auth0.callbackUrl,
      },
      (err, result) => {
        if (err) reject();
        else {
          store
            .dispatch("users/refreshAuth", result)
            .then(resolve)
            .catch(reject);
        }
      }
    );
  });
};

export function checkToken() {
  raven.captureBreadcrumb("axios", "checkToken", "api", {});
  const token = store.getters["users/accessToken"];
  return new Promise((resolve, reject) => {
    if (token && isTokenExpired(token)) {
      refreshToken().then(resolve).catch(reject);
    } else {
      resolve();
    }
  });
}

export async function getToken() {
  raven.captureBreadcrumb("axios", "getToken", "api", {});
  await checkToken();
  const token = store.getters["users/accessToken"];
  return token;
}
