import config from "../../config/config";
import AbstractRestService from "@/api/abstractRestService";
import type Site from "@/types/Site";

/**
 * Flex account operations
 */
export interface FlexAccountService {
  /**
   * Get all flex sites by account id.
   */
  getSitesByAccount(accountId: number): Promise<Site[]>;
}

/**
 * HTTP Flex service implementation
 *
 * @implements {FlexUserService}
 */
export class FlexAccountServiceRestImpl
  extends AbstractRestService
  implements FlexAccountService
{
  /**
   * Init
   * @param authRequired
   */
  constructor(authRequired?: boolean) {
    super(config.api.base_url_v2, authRequired);
  }

  /**
   * @param accountId
   */
  getSitesByAccount(accountId: number): Promise<Site[]> {
    return this.getRestClient()
      .then((client) => client.get("flex/sites", { params: { accountId } }))
      .catch((error: any) => Promise.reject(error));
  }
}

export default new FlexAccountServiceRestImpl();
