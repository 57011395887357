import {
  type NavigationGuardNext,
  type RouteLocationNormalized,
  useRouter,
} from "vue-router";
import { useBreadcrumbStore } from "@/stores/breadcrumbs";

interface Breadcrumb {
  title: string;
  to: string;
  disabled: boolean;
}

export function createBreadcrumbGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  // do not reset breadcrumbs if route did not change
  if (to.name === from.name) {
    if (typeof next === "function") {
      next();
    }
    return;
  }

  const breadcrumbStore = useBreadcrumbStore();
  const router = useRouter();

  let breadcrumbs: Breadcrumb[];

  const lastItem = to.matched[to.matched.length - 1];

  if (lastItem?.meta?.disableBreadcrumbs) {
    breadcrumbs = [];
  } else {
    breadcrumbs = to.matched
      .filter((match) => !match?.meta?.abstract)
      .map((match, index, arr) => {
        const title = match.meta.displayName || match.meta.title || match.name;

        return {
          title: title ? String(title) : "",
          to: router.resolve(match, to).href,
          disabled: index === arr.length - 1,
        };
      });
  }

  breadcrumbStore.setBreadcrumbs(breadcrumbs);

  if (typeof next === "function") {
    next();
  }
}
