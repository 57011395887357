export const RECEIVE_ACCOUNTS = "RECEIVE_ACCOUNTS";
export const RECEIVE_ACCOUNT = "RECEIVE_ACCOUNT";
export const RECEIVE_ACCOUNT_NAME = "RECEIVE_ACCOUNT_NAME";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const UPDATE_ITEM_PROP = "UPDATE_ITEM_PROP";
export const UPDATE_ITEM = "UPDATE_ITEM";
export const UPDATE_SITE = "UPDATE_SITE";
export const ADD_SITE = "ADD_SITE";
export const RECEIVE_SITES_FOR_ACCOUNT = "RECEIVE_SITES_FOR_ACCOUNT";
export const RECEIVE_FLEX_SITES_FOR_ACCOUNT = "RECEIVE_FLEX_SITES_FOR_ACCOUNT";
export const RECEIVE_SITE_FOR_ACCOUNT = "RECEIVE_SITE_FOR_ACCOUNT";
export const RECEIVE_SITES = "RECEIVE_SITES";
export const RECEIVE_REPORT_TYPES = "RECEIVE_REPORT_TYPES";
export const ALL_LOADED = "ALL_LOADED";
export const SET_ACTIVE_SITE_IDS = "SET_ACTIVE_SITE_IDS";
export const SAVE_REPORT = "SAVE_REPORT";
export const SET_REPORT_NAME = "SET_REPORT_NAME";
export const RECEIVE_METADATA_OPTIONS = "RECEIVE_METADATA_OPTIONS";
export const SET_REPORTS_HIDDEN_STATUS = "SET_REPORTS_HIDDEN_STATUS";
export const SET_REPORT_SITE_FILTER_VALUES = "SET_REPORT_SITE_FILTER_VALUES";
export const SET_STAFF_SITE_ID = "SET_STAFF_SITE_ID";
export const SWITCHING_ACCOUNT = "SWITCHING_ACCOUNT";
