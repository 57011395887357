import { ref, type Ref } from "vue";
import featureFlags from "@/lib/helpers/configCat";

export type UseFeatureFlag = {
  flag: Ref<boolean>;
};

/**
 *
 */
export default function useBooleanFeatureFlag(name: string): UseFeatureFlag {
  const flag = ref<boolean>(false);

  (async function () {
    flag.value = await featureFlags.getBooleanValueByEmail(name);
  })();

  return { flag };
}
