import store from "@/store";
import gtagEvent from "@/lib/helpers/gtag";
import config from "@/config/config";

/**
 * Global After Hook for Google Analytics pageview tracking
 *
 * @see https://router.vuejs.org/en/advanced/navigation-guards.html
 * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications
 */
const googleAnalyticsAfterHook = async (to) => {
  if (config.app.env === "PROD") {
    if (to.meta.requiresAuth) {
      const accountNamePresent = !!store.getters["accounts/accountName"];
      const accountIdPresent = !!store.getters["users/accountId"];
      if (!accountNamePresent && accountIdPresent) {
        console.log("acct name not present");
        await store.dispatch("accounts/getAccountName");
      }
    }
    let userType = "unauthenticated";
    if (store.getters["users/isAdmin"]) {
      userType = "admin";
    } else if (store.getters["users/isPublisher"]) {
      userType = "publisher";
    } else if (store.getters["users/isFlexUser"]) {
      userType = "publisher_flex";
    }
    gtagEvent("page_view", {
      page_location: to.path,
      user_id: store.getters["users/userId"],
      account_name: store.getters["accounts/accountName"],
      user_type: userType,
    });
  }
};

export default googleAnalyticsAfterHook;
