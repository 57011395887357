import { defineStore } from "pinia";

export interface Breadcrumb {
  title: string;
  href?: string;
  to?: string;
  disabled: boolean;
}

export const useBreadcrumbStore = defineStore("breadcrumbs", {
  state: () => ({
    breadcrumbs: [] as Breadcrumb[],
  }),
  actions: {
    setBreadcrumbs(breadcrumbs: Breadcrumb[]) {
      this.breadcrumbs = breadcrumbs;
    },

    replaceAt(index: number, breadcrumb: Breadcrumb) {
      if (index < 0) {
        index = this.breadcrumbs.length + index;
      }

      if (index >= 0 && index < this.breadcrumbs.length) {
        this.breadcrumbs[index] = breadcrumb;
      }
    },

    updateCurrentPageName: function (pageName: string) {
      if (Array.isArray(this.breadcrumbs) && this.breadcrumbs.length > 0) {
        this.breadcrumbs[this.breadcrumbs.length - 1].title = pageName;
      } else {
        console.warn("Trying to update empty breadcrumb list");
      }
    },
  },
});
