import Client from "./client";
import config from "../config/config";
import { checkToken } from "./tokens";

let accountsClient;

/**
 * Lazy-loaded method to get an API client at runtime
 * @return {client.ApiClient}
 */
const getClient = (authRequired = true) =>
  new Promise((resolve) => {
    if (authRequired) {
      checkToken().then(() => {
        accountsClient = new Client.ApiClient(config.api.base_url);
        // accountsClient = new Client.ApiClient('http://127.0.0.1:3006/api/v1');
        resolve(accountsClient);
      });
    } else {
      accountsClient = new Client.ApiClient(config.api.base_url);
      // accountsClient = new Client.ApiClient('http://127.0.0.1:3006/api/v1');
      resolve(accountsClient);
    }
  });

const getClientV2 = (authRequired = true) =>
  new Promise((resolve) => {
    if (authRequired) {
      checkToken().then(() => {
        accountsClient = new Client.ApiClient(config.api.base_url_v2);
        // accountsClient = new Client.ApiClient('http://127.0.0.1:3006/api/v2');
        resolve(accountsClient);
      });
    } else {
      accountsClient = new Client.ApiClient(config.api.base_url_v2);
      // accountsClient = new Client.ApiClient('http://127.0.0.1:3006/api/v2');
      resolve(accountsClient);
    }
  });

export default {
  getAccounts() {
    return getClient()
      .then((client) => client.get("accounts"))
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  get(id) {
    return getClient()
      .then((client) => client.get(`accounts/${id}`))
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  getName(id) {
    return getClient()
      .then((client) => client.get(`accounts/${id}?pubSafe`))
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  getCategoryForAccount(accountId) {
    return getClient()
      .then((client) => client.get(`accounts/${accountId}/category`))
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  getSitesForAccount(accountId) {
    return getClient().then((client) =>
      client.get("sites", { params: { accountId } })
    );
  },
  getFlexSitesForAccount(accountId) {
    return getClientV2().then((client) =>
      client.get("flex/sites", { params: { accountId } })
    );
  },
  getAllSites() {
    return getClient().then((client) => client.get("sites?status=all"));
  },
  getActiveSites() {
    return getClient().then((client) => client.get("sites?status=active"));
  },
  getSite(id) {
    return getClient()
      .then((client) => client.get(`sites/${id}`))
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  getReportTypes(accountId) {
    return getClient()
      .then((client) => client.get(`accounts/${accountId}/reporttypes`))
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
};
