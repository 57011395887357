import Client from "./client";
import config from "../config/config";
import { checkToken } from "./tokens";
import objectHasProperty from "@/lib/helpers/objectHasProperty";
import type Network from "@/types/Network";

let networksClient;

/**
 * Lazy-loaded method to get an API client at runtime
 * @return {client.ApiClient}
 */
const getClient = async (authRequired = true): Promise<any> =>
  new Promise((resolve) => {
    if (authRequired) {
      checkToken().then(() => {
        networksClient = new Client.ApiClient(config.api.base_url);
        // networksClient = new Client.ApiClient('http://127.0.0.1:3003/api/v1');
        resolve(networksClient);
      });
    } else {
      networksClient = new Client.ApiClient(config.api.base_url);
      resolve(networksClient);
    }
  });

/**
 * Format networks for UI presentation
 *
 * @return Object
 * @param network
 */
function deserialize(network: Network) {
  const n = network;

  if (!objectHasProperty(n, "sizes")) {
    return n;
  }

  n.sizes = n.sizes.map((sizeMap) => {
    const map = sizeMap;

    map.size = [`${map.size[0]}x${map.size[1]}`];
    return map;
  });

  return n;
}

export default {
  allActive(deserializeEnabled = true): Promise<{ networks: Network[] }> {
    return getClient()
      .then((client) => client.get("networks"))
      .then((response: { data: { networks: Network[] } }) => {
        const { data } = response;
        if (deserializeEnabled) {
          // If this is true, you won't be able to save networks that have sizes defined
          data.networks = data.networks.map((network) => deserialize(network));
        }
        data.networks = data.networks.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        return Promise.resolve(data);
      })
      .catch((error) => Promise.reject(error));
  },
  all(deserializeEnabled = true): Promise<{ networks: Network[] }> {
    // Should only be used for network watermarks
    return getClient()
      .then((client) => client.get("networks/all"))
      .then((response: { data: { networks: Network[] } }) => {
        const { data } = response;
        if (deserializeEnabled) {
          // If this is true, you won't be able to save networks that have sizes defined
          data.networks = data.networks.map((network) => deserialize(network));
        }
        data.networks = data.networks.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        return Promise.resolve(data);
      })
      .catch((error) => Promise.reject(error));
  },
};
