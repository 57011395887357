import AbstractRestService from "@/api/abstractRestService";
import config from "@/config/config";

export interface ZendeskService {
  /**
   * Get a Zendesk Token for the Chat Widget
   */
  getZendeskToken(): Promise<string>;
  /**
   * Get a Zendesk Token for SSO - Used to access Resource Center
   * @see https://support.zendesk.com/hc/en-us/articles/4408845838874-Enabling-JWT-single-sign-on
   */
  getZendeskSsoToken(): Promise<string>;
}

export class ZendeskServiceImpl
  extends AbstractRestService
  implements ZendeskService
{
  constructor() {
    super(config.api.base_url, true);
    // super("http://127.0.0.1:3004/api/latest", true);
  }

  async getZendeskToken(): Promise<string> {
    try {
      const client = await this.getRestClient();
      const response = await Promise.resolve(
        client.get("/authorization/zendesk/generate-token")
      );
      return response.data;
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async getZendeskSsoToken(): Promise<string> {
    try {
      const client = await this.getRestClient();
      const response = await Promise.resolve(
        client.get("/authorization/zendesk/generate-sso-token")
      );
      return response.data;
    } catch (error) {
      return await Promise.reject(error);
    }
  }
}

export default new ZendeskServiceImpl();
