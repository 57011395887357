/* eslint-disable no-shadow,no-param-reassign */
import * as types from "./mutation-types";
import placementsApi from "../../../api/placements";

const state = {
  placementNames: [],
};

const getters = {
  placementNames: (state) => state.placementNames,
};

const actions = {
  async getPlacementNames({ commit }, sites) {
    const response = await placementsApi.getPlacementNames(sites);
    commit(types.RECEIVE_PLACEMENT_NAMES, { placements: response });
    return response;
  },
};

const mutations = {
  [types.RECEIVE_PLACEMENT_NAMES](state, { placements }) {
    state.placementNames = placements;
  },
};

/**
 * The accounts Vuex module
 * @see https://vuex.vuejs.org/en/modules.html
 */
export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
