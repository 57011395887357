import { createVuetify } from "vuetify";

// import "vuetify/styles";
import { aliases, mdi } from "vuetify/iconsets/mdi";

const vuetify = createVuetify({
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
    },
  },
  theme: {
    themes: {
      light: {
        colors: {
          primary: "#00c389", // Freestar green as primary
          secondary: "#2d2926",
          background: "#ffffff",
          // surface: "#f5f5f5",
          error: "#f87f7d",
          info: "#f2f2f2",
          success: "#77eca6",
          warning: "#ffdb80",
          warning_light: "#fff1cc",
        },
        variables: {
          "font-family--heading": "Montserrat, sans-serif",
          "font-family--body": "IBM Plex Sans, sans-serif",

          "font-size--base": "14px",
          "font-size--tiny": "12px",
          "font-size--section": "18px",
          "font-size--subtitle": "20px",
          "font-size--title": "32px",

          "font-weight--base": 400,
          "font-weight--med": 600,
          "font-weight--bold": 700,

          "font-letter-spacing--base": "normal",

          "font-line-height--base": "20px",
          "font-line-height--tiny": "16px",
          "font-line-height--section": "24px",
          "font-line-height--subtitle": "32px",
          "font-line-height--title": "42px",

          // Color Tokens
          "color-font-text_primary": "#2d2926",
          "color-font-text_secondary": "#595959",
          "color-font-text_quiet": "#666666",
          "color-font-text_reverse": "#ffffff",
          "color-font-text_black": "#000000", // not in figma theme, but used for headings

          "color-UI-bg_primary": "#ffffff",
          "color-UI-bg_secondary": "#d8d8d8",
          "color-UI-bg_quiet": "#f2f2f2",
          "color-UI-bg_modal": "rgba(45, 41, 38, 0.8)",

          "color-brand-brand_primary": "#00c389",
          "color-brand-brand_secondary": "#2d2926",

          "color-CTA-action_primary": "#00c389",
          "color-CTA-action_secondary": "#ffffff",
          "color-CTA-action_textLink": "#008046",
          "color-CTA-active_primary": "rgba(0, 0, 0, 0.87)",

          "color-UI-line": "#cccccc",
          "color-UI-icon": "#00c389",

          // Status Colors
          "color-status_info": "#f2f2f2",
          "color-status_success": "#77eca6",
          "color-status_warning": "#ffdb80",
          "color-status_danger": "#f87f7d",
          "color-status-info_accent": "#7c52ff",
          "color-status-info_loud": "#98e7ff",

          // Radii
          "radii--s": "4px",
          "radii--m": "8px",
          "radii--l": "16px",
          "radii--xl": "28px",
          "radii--round": "100%",

          // Spacing
          "spacing--singleSpace": "0px",
          "spacing--onehalfSpace": "4px",
          "spacing--doubleSpace": "8px",
          "spacing--tripleSpace": "12px",
          "spacing--paragraphSpace": "16px",
          "spacing--sectionSpace": "24px",
          "spacing--marginSpace": "32px",
        },
      },
    },
  },
  defaults: {
    VBreadcrumbs: {
      class: "text__tiny",
    },
    VCardTitle: {
      tag: "h2",
    },
    VCardSubtitle: {
      class: "text__body",
    },
    VSelect: {
      style: {
        borderRadius: "var(--v-radii-m, 8px)",
      },
    },
    VField: {
      style: {
        borderRadius: "var(--v-radii-m, 8px)",
      },
    },
    VExpansionPanels: {
      style: {
        border: "1px solid rgb(var(--v-color-UI-line))",
        borderRadius: "var(--v-radii-m, 8px)",
      },
    },
  },
});

export default vuetify;
