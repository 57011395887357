import store from "../../store";

/**
 * Navigation guard to redirect logged-in users from login to dashboard.
 */
const redirectIfAuthenticated = (to, from, next) => {
  if (to.name === "login" && store.getters["users/isAuthenticated"]) {
    return next({ name: "dashboard" });
  }
  return next();
};

export default redirectIfAuthenticated;
