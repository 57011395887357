import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { UsersStore } from "@/stores/user";
import { storeToRefs } from "pinia";

export function userImpersonationGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const userStore = UsersStore();

  const { impersonating, impersonationAllowedPaths } = storeToRefs(userStore);

  if (impersonating.value) {
    if (
      impersonationAllowedPaths.value.some((path) => to.path.startsWith(path))
    ) {
      return next();
    } else {
      return next({ path: "/dashboard" });
    }
  } else {
    return next();
  }
}
