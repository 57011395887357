import { Userpilot } from "userpilot";
import config from "@/config/config";
import analyticsUserData from "@/lib/helpers/analyticsUserData";

/**
 * Helper functions for Userpilot
 *
 * @see https://www.npmjs.com/package/userpilot?activeTab=readme
 * @see https://docs.userpilot.com/category/13-developer
 * @see https://docs.userpilot.com/article/22-install-userpilot-on-single-page-application-frameworks#:~:text=VueJS,Identify%20users%20to%20Userpilot
 */

interface UserpilotInstance {
  initialize: (str: string, settings?: object) => string;
  reload: (url?: any) => void;
  identify: (userid: string, parameters?: object) => void;
  destroy: () => void;
}

interface ExtendedUserPilot extends UserpilotInstance {
  initialized: number; // exists on window.userpilot
}

declare global {
  interface Window {
    userpilot: ExtendedUserPilot;
  }
}

const hasCorrectEnv = config.app.env === "prod";
const hasUserpilot = () => hasCorrectEnv && window.userpilot !== undefined;
const isUserpilotInitialized = () =>
  hasUserpilot() && window.userpilot.initialized === 1;

export function userpilotInit() {
  if (!isUserpilotInitialized()) {
    Userpilot.initialize(config.userpilot.token);
  } else {
    console.warn("Skipping Userpilot init");
  }
}

export async function userpilotIdentify() {
  if (hasUserpilot()) {
    const { user, account } = await analyticsUserData.getUserAndAccountData();

    Userpilot.identify(user.id, {
      name: user.name,
      email: user.email,
      role: user.type,

      company: {
        id: account.id,
        name: account.name,
      },
    });
  } else {
    console.warn("Skipping Userpilot identify");
  }
}

export function userpilotDestroy() {
  if (hasUserpilot()) {
    Userpilot.destroy();
  } else {
    console.warn("Skipping Userpilot destroy");
  }
}
