import { defineStore } from "pinia";
import { computed, ref } from "vue";
import useBooleanFeatureFlag from "@/composables/useFeatureFlag";
import useEventEmitter from "@/composables/useEmitter";
import type { CurrentUserData, ImpersonatedUser } from "@/types/Impersonation";
import { useToggle } from "@vueuse/core";
import store from "@/store";

/**
 * Managed user state and actions
 */
export const UsersStore = defineStore("users", () => {
  /**
   * get event emitter
   */
  const { emitter } = useEventEmitter();

  /**
   * Get feature flag for UI
   */
  const impersonationFlag = useBooleanFeatureFlag("enableUserImpersonation");

  /**
   * is feature flag enabled?
   */
  const isUserImpersonationEnabled = computed(
    () => impersonationFlag.flag.value
  );

  /**
   * used for profile impersonation
   */
  const impersonating = ref<boolean>(false);

  /**
   * Current user being impersonated
   */
  const impersonatedProfile = ref<ImpersonatedUser | null>(null);

  /**
   * Logged in user data
   */
  const currentUserData = ref<CurrentUserData | null>(null);

  /**
   * used by admins to navigate flex accounts
   */
  const impersonatingFlexUsers = ref<boolean>(false);

  /**
   * Controls modal dialog visibility
   */
  const showUserImpersonationDialog = ref<boolean>(false);

  /**
   * Paths
   */
  const impersonationAllowedPaths = ref<string[]>([
    "/login",
    "/logout",
    "/dashboard",
    "/advanced-reporting",
    "/flex/dashboard",
    "/flex/advanced-reporting",
  ]);

  /**
   * toggle dialog function
   */
  const toggleImpersonationDialog = useToggle(showUserImpersonationDialog);

  /**
   * Is the user being impersonated an administrator?
   */
  const isAdminImpersonation = computed<boolean>(() => {
    return impersonatedProfile.value
      ? impersonatedProfile.value.isAdmin
      : false;
  });

  /**
   * Set current user data. The currentUserData prop is initialized once since
   * accountId and userId are both globals and must be changed for impersonate
   * another user, is the only way of keeping the current user's data.
   * @param userData
   */
  function setCurrentUserData(userData: CurrentUserData) {
    if (!currentUserData.value) {
      currentUserData.value = userData;
    }
  }

  /**
   * Save impersonated user data
   * @param user
   */
  async function impersonate(user: ImpersonatedUser) {
    impersonating.value = true;
    impersonatedProfile.value = user;

    // update store
    await getSitesConfigurationForUser(user.accountId);
    setCurrentAccount(user.accountId);

    // notify listeners
    emitter.emit("onImpresonate", user);
  }

  /**
   * Restore Profile
   */
  async function removeImpersonation() {
    impersonatedProfile.value = null;
    impersonating.value = false;

    await getSitesConfigurationForUser(currentUserData.value!.accountId);
    setCurrentAccount(currentUserData.value!.accountId);

    // notify listeners
    emitter.emit("onRemove", currentUserData.value!);
  }

  /**
   * Set the current account
   *
   * @param accountId
   */
  function setCurrentAccount(accountId: number) {
    store.commit("users/SET_CURRENT_ACCOUNT", accountId);
  }

  /**
   * Update sites for the given account
   *
   * @param accountId
   */
  async function getSitesConfigurationForUser(accountId: number) {
    await store.dispatch("accounts/setActiveSiteIds", []);
    await store.dispatch("accounts/getSitesForAccount", accountId);
  }

  return {
    impersonating,
    impersonatedProfile,
    impersonatingFlexUsers,
    isAdminImpersonation,
    currentUserData,
    isUserImpersonationEnabled,
    showUserImpersonationDialog,
    impersonationAllowedPaths,
    setCurrentUserData,
    impersonate,
    removeImpersonation,
    toggleImpersonationDialog,
  };
});
