import store from "../../store";
import auth from "../../api/auth0";
import config from "../../config/config";
import getCookieMixin from "../../mixins/get-cookie";
/**
 * Global navigation guard to check if a user is authorized
 *
 * @see https://router.vuejs.org/en/advanced/navigation-guards.html
 */

const checkAuthBefore = (to, from, next) => {
  // This route requires auth, check if logged in
  // If not, redirect to login page.
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters["users/isAuthenticated"]) {
      store.dispatch("users/setIntendedRoute", to);
      return store
        .dispatch("users/signOut")
        .then(() => next({ name: "login" }));
    }
    if (store.getters["users/isTokenExpired"]) {
      if (
        getCookieMixin.methods.getCookie("fs_kmli") &&
        getCookieMixin.methods.getCookie("fs_kmli") === "true"
      ) {
        auth.checkSession(
          {
            redirectUri: config.auth0.callbackUrl,
          },
          (err, result) => {
            if (err) {
              console.log(err);
              store.dispatch("users/setIntendedRoute", to);
              return store
                .dispatch("users/signOut")
                .then(() => next({ name: "login" }));
            }
            store.dispatch("users/refreshAuth", result).then(() => {
              if (to.path === "/") {
                return next({ name: "dashboard" });
              }
              return next();
            });
          }
        );
      } else {
        store.dispatch("users/setIntendedRoute", to);
        return store
          .dispatch("users/signOut")
          .then(() => next({ name: "login" }));
      }
    } else if (!store.getters["users/isAuthenticated"]) {
      // Track the intended route to redirect later
      store.dispatch("users/setIntendedRoute", to);
      return store
        .dispatch("users/signOut")
        .then(() => next({ name: "login" }));
    } else {
      // Authenticated users can proceed
      return next();
    }
  } else {
    return next();
  }
};

/**
 * Global Vue Router navigation guard to check authentication
 * @see https://router.vuejs.org/en/advanced/navigation-guards.html
 */
export default checkAuthBefore;
