import type { Commit } from "vuex";
import type {
  AdBlockRecoveryConfig,
  AdBlockRecoveryPartialConfig,
} from "@/types/AdBlockRecoveryConfig";
import pubApi from "@/api/pub";
import * as types from "./mutation-types";
import type Site from "@/types/Site";
import type { AdBlockRecoveryLanguage } from "@/types/AdBlockRecoveryConfig";
import type { ContentLanguageOption } from "@/components/siteconfig/AdBlockRecoveryConfigForm.vue";

interface State {
  isLoading: boolean;
  selectedSiteId: number | null;
  defaultConfig: AdBlockRecoveryConfig | null;
  currentConfig: AdBlockRecoveryConfig | null;
  configsForAccount: AdBlockRecoveryPartialConfig[] | null;
  contentLanguageOptions: ContentLanguageOption[] | null;
}

const state: State = {
  isLoading: false,
  selectedSiteId: null,
  defaultConfig: null,
  currentConfig: null,
  configsForAccount: null,
  contentLanguageOptions: null,
};

const getters = {
  isLoading: (state: State) => state.isLoading,
  selectedSiteId: (state: State) => state.selectedSiteId,
  defaultConfig: (state: State) => state.defaultConfig,
  currentConfig: (state: State) => state.currentConfig,
  configsForAccount: (state: State) => state.configsForAccount,
  contentLanguageOptions: (state: State) => state.contentLanguageOptions,
};

const actions = {
  setSelectedSiteId({ commit }: { commit: Commit }, siteId: number | null) {
    commit(types.UPDATE_SELECTED_SITE_ID, siteId);
    if (siteId === null) {
      commit(types.RECEIVE_CONFIG, null);
      commit(types.RECEIVE_DEFAULT_CONFIG, null);
    }
  },
  async fetchDefaultConfig({ commit }: { commit: Commit }, site: Site) {
    if (!site) {
      throw new Error("Unable to extract site data");
    }
    try {
      commit(types.SET_IS_LOADING, true);
      const config = await pubApi.adBlockRecovery.getDefaultConfig();
      // set id, account id, path passed down from Site object
      config.siteId = site.id;
      config.accountId = site.accountId;
      config.path = site.path;
      delete config.id;
      commit(types.RECEIVE_DEFAULT_CONFIG, config);
    } finally {
      commit(types.SET_IS_LOADING, false);
    }
  },
  async fetchConfigBySiteId({ commit }: { commit: Commit }, siteId: number) {
    try {
      commit(types.SET_IS_LOADING, true);
      const config = await pubApi.adBlockRecovery.getConfigBySiteId(siteId);
      commit(types.RECEIVE_CONFIG, config);
    } finally {
      commit(types.SET_IS_LOADING, false);
    }
  },
  async fetchConfigsByAccountId(
    { commit }: { commit: Commit },
    accountId: number
  ) {
    try {
      commit(types.SET_IS_LOADING, true);
      const pageableConfigs =
        await pubApi.adBlockRecovery.getConfigsByAccountId(accountId);
      commit(types.RECEIVE_CONFIGS_FOR_ACCOUNT, pageableConfigs?.items || []);
    } finally {
      commit(types.SET_IS_LOADING, false);
    }
  },
  async saveConfig(
    { commit }: { commit: Commit },
    {
      siteId,
      isNew,
      data,
    }: { siteId: number; isNew: boolean; data: AdBlockRecoveryConfig }
  ) {
    try {
      commit(types.SET_IS_LOADING, true);
      const saveAction = isNew
        ? pubApi.adBlockRecovery.create
        : pubApi.adBlockRecovery.update;
      const config = await saveAction(siteId, data);
      commit(types.RECEIVE_CONFIG, config);
      return config;
    } finally {
      commit(types.SET_IS_LOADING, false);
    }
  },
  async publishConfig(
    { commit }: { commit: Commit },
    {
      siteId,
      environment,
    }: { siteId: number; environment: "PREVIEW" | "PRODUCTION" }
  ) {
    try {
      commit(types.SET_IS_LOADING, true);
      const resStatus: number = await pubApi.adBlockRecovery.publish(
        siteId,
        environment
      );
      console.log(resStatus);
    } finally {
      commit(types.SET_IS_LOADING, false);
    }
  },
  async fetchContentLanguages({ commit }: { commit: Commit }) {
    const languages = await pubApi.adBlockRecovery.getLanguages();
    const contentLanguages: ContentLanguageOption[] = languages.map(
      (lang: AdBlockRecoveryLanguage) => {
        return <ContentLanguageOption>{ key: lang.code, label: lang.name };
      }
    );
    commit(types.RECEIVE_LANGUAGES, contentLanguages || []);
  },
};

const mutations = {
  [types.SET_IS_LOADING](state: State, isLoading: boolean) {
    state.isLoading = isLoading;
  },
  [types.RECEIVE_CONFIG](state: State, config: AdBlockRecoveryConfig) {
    state.currentConfig = config;
  },
  [types.RECEIVE_CONFIGS_FOR_ACCOUNT](
    state: State,
    items: AdBlockRecoveryPartialConfig[]
  ) {
    state.configsForAccount = items;
  },
  [types.RECEIVE_DEFAULT_CONFIG](state: State, config: AdBlockRecoveryConfig) {
    state.defaultConfig = config;
  },
  [types.UPDATE_SELECTED_SITE_ID](state: State, siteId: number | null) {
    state.selectedSiteId = siteId;
  },
  [types.RECEIVE_LANGUAGES](state: State, languages: ContentLanguageOption[]) {
    state.contentLanguageOptions = languages;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
