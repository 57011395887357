import config from "../config/config";
import AbstractRestService from "@/api/abstractRestService";
import type {
  PrebidNetwork,
  PrebidSiteConfig,
  PrebidSiteConfigVersion,
} from "@/types/PrebidSiteConfig";
import type { AxiosResponse } from "axios";

function deserialize(network: PrebidNetwork) {
  const n = network;
  if (n.bidAdjustment === undefined || n.bidAdjustment === null) {
    n.bidAdjustment = null;
  }
  if (n.deviceExclusive === undefined) {
    n.deviceExclusive = null;
  }
  if (n.preferredDesktop === undefined || n.preferredDesktop === null) {
    n.preferredDesktop = false;
  }
  if (n.preferredMobile === undefined || n.preferredMobile === null) {
    n.preferredMobile = false;
  }
  try {
    n.sizes = n.sizes.map((sizeMap) => {
      const map = sizeMap;
      map.size = Array.isArray(map.size)
        ? map.size.map((size) => `${size[0]}x${size[1]}`)
        : map.size;
      if (map.deviceExclusive === undefined) map.deviceExclusive = null;

      return map;
    });
  } catch (err) {
    n.sizes = [];
  }

  return n;
}

function setDefaults(
  res: AxiosResponse<PrebidSiteConfig>,
  rawFormat: boolean = false
): PrebidSiteConfig {
  const { data } = res;
  // Please set defaults in prebid-api - VR

  if (!rawFormat) {
    data.networks = data.networks.map((network) => deserialize(network));
  }
  data.placements = (data?.placements || []).map((placement) => {
    const p = placement;
    if (!rawFormat && p.networks && p.networks.length) {
      p.networks = p.networks.map((network: PrebidNetwork) =>
        deserialize(network)
      );
    }
    return p;
  });
  return data;
}

export interface PrebidService {
  /**
   * Get a single Prebid Config for a given site id
   */
  getPrebidConfigBySiteId(siteId: number): Promise<PrebidSiteConfig>;
}

/**
 * HTTP Prebid Service implementation
 *
 * @implements {PrebidService}
 */
export class PrebidRestImpl
  extends AbstractRestService
  implements PrebidService
{
  /**
   * Init
   * @param authRequired
   */
  constructor(authRequired: boolean = true) {
    super(config.api.base_url_v2, authRequired);
    // super("http://127.0.0.1:3009/api/v2", authRequired);
  }

  /**
   * @param siteId
   */
  getPrebidConfigBySiteId(siteId: number): Promise<PrebidSiteConfig> {
    return this.getRestClient()
      .then((client) => client.get("prebid", { params: { siteId } }))
      .then((response) => Promise.resolve(response.data))
      .catch((error: any) => Promise.reject(error));
  }

  getPrebidConfigVersionsBySiteId(
    siteId: number
  ): Promise<PrebidSiteConfigVersion[]> {
    return this.getRestClient()
      .then((client) => client.get("prebid/versions", { params: { siteId } }))
      .then((response) => Promise.resolve(response.data))
      .catch((error: any) => Promise.reject(error));
  }

  getVersion(versionId: string): Promise<PrebidSiteConfig> {
    return this.getRestClient()
      .then((client) => client.get(`prebid/versions/${versionId}`))
      .then((response) => {
        const processedData = setDefaults(response);
        return Promise.resolve(processedData);
      })
      .catch((error) => Promise.reject(error));
  }

  create(
    siteId: number,
    body: PrebidSiteConfig,
    isActive: boolean = false
  ): Promise<PrebidSiteConfig> {
    return this.getRestClient()
      .then((client) =>
        client.post(`prebid?activate=${isActive}`, body, { params: { siteId } })
      )
      .then((response) => {
        const { data } = response;
        data.networks = data.networks.map((network: PrebidNetwork) =>
          deserialize(network)
        );
        data.placements = data.placements.map((placement: any) => {
          const p = placement;
          if (p.networks && p.networks.length) {
            p.networks = p.networks.map((network: PrebidNetwork) =>
              deserialize(network)
            );
          }
          return p;
        });
        return Promise.resolve(data);
      })
      .catch((error) => Promise.reject(error));
  }

  activateVersion(versionId: string): Promise<PrebidSiteConfig> {
    return this.getRestClient()
      .then((client) => client.put(`prebid/activate/${versionId}`))
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  }
}

export default new PrebidRestImpl();
