import Client from "./client";
import config from "../config/config";
import { checkToken } from "./tokens";

let usersClient;

/**
 * Lazy-loaded method to get an API client at runtime
 * @return {client.ApiClient}
 */
const getClient = (authRequired = true) =>
  new Promise((resolve) => {
    if (authRequired) {
      checkToken().then(() => {
        usersClient = new Client.ApiClient(config.api.base_url);
        // usersClient = new Client.ApiClient('http://127.0.0.1:3004/api/latest');
        resolve(usersClient);
      });
    } else {
      usersClient = new Client.ApiClient(config.api.base_url);
      resolve(usersClient);
    }
  });

export default {
  // data: {userId, old password, new password}
  changePassword(data) {
    return getClient()
      .then((client) =>
        client.put("/authorization/users/confirmAndChangePassword", data)
      )
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  // data: {new email address}
  requestChangeEmail(data) {
    return getClient()
      .then((client) =>
        client.post("/authorization/users/requestChangeEmail", data)
      )
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  // data: {userID}
  confirmChangeEmail(token, data) {
    return getClient()
      .then((client) =>
        client.post(
          `/authorization/users/confirmChangeEmail?token=${token}`,
          data
        )
      )
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  // response.data: email
  getEmailAddress() {
    return getClient()
      .then((client) => client.get("/authorization/users/getEmailAddress"))
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  /**
   * @returns list of company names/ids from user's JWT
   {
      "id": "79",
      "name": "Freestar"
    }
   */
  getCompanies() {
    return getClient()
      .then((client) => client.get("/authorization/users/companies"))
      .then((response) => Promise.resolve(response.data.companies))
      .catch((error) => Promise.reject(error));
  },
  // response.data: {email, phoneNumber, adsTxtNotify}
  getUserProfile() {
    return getClient()
      .then((client) => client.get("/authorization/users/profile"))
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  // data: {phoneNumber, adsTxtNotify}
  updateUserProfile(data) {
    return getClient()
      .then((client) => client.put("/authorization/users/profile", data))
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  // data: {email, password}
  generateAppToken(data) {
    return getClient()
      .then((client) => client.post("authorization/pubauth", data))
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  getAllUsers() {
    return getClient()
      .then((client) => client.get("/authorization/users"))
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  getUsersQuery(params) {
    return getClient()
      .then((client) => client.get("/authorization/users/query", { params }))
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
};
