import store from "@/store";

interface UserData {
  id: string;
  name: string;
  email: string;
  type: string;
}

interface AccountData {
  id: string;
  name: string;
}

const getUserData = (): UserData => {
  let userType = "unauthenticated";
  if (store.getters["users/isAdmin"]) {
    userType = "admin";
  } else if (store.getters["users/isPublisher"]) {
    userType = "publisher";
  } else if (store.getters["users/isFlexUser"]) {
    userType = "publisher_flex";
  }

  return {
    id: store.getters["users/userId"],
    name: store.getters["users/username"],
    email: store.getters["users/email"],
    type: userType,
  };
};

const getAccountData = async (): Promise<AccountData> => {
  const accountNamePresent = !!store.getters["accounts/accountName"];
  const accountId = store.getters["users/accountId"];
  if (!accountNamePresent && !!accountId) {
    await store.dispatch("accounts/getAccountName", accountId);
  }

  return {
    id: store.getters["users/accountId"],
    name: store.getters["accounts/accountName"],
  };
};

export default {
  getUserData,
  getAccountData,
  getUserAndAccountData: async () => ({
    user: getUserData(),
    account: await getAccountData(),
  }),
};
