import {
  type AbTest,
  type AbTestView,
  type AdStack,
  AbTestStatus,
  TestGroup,
} from "@/types/AbTest";
import type { PrebidSiteConfig } from "@/types/PrebidSiteConfig";
import { computed } from "vue";

const STATUS_COLORS = {
  [AbTestStatus.Running]: "#77ECA6",
  [AbTestStatus.Scheduled]: "#98E7FF",
  [AbTestStatus.Cancelled]: "#F2F2F2",
  [AbTestStatus.Completed]: "#EEE5FF",
} as const;

export function getStatusColor(status: AbTestStatus): string {
  return STATUS_COLORS[status] || "#f0f0f0";
}

export function getStatus(abTest: AbTest): AbTestStatus {
  const now = new Date();

  const startDate = new Date(`${abTest.startDate}T00:00:00`);
  const endDate = new Date(`${abTest.endDate}T23:59:59`);

  if (abTest.earlyTermination) {
    return AbTestStatus.Cancelled;
  }

  if (abTest.started) {
    if (now > endDate) {
      return AbTestStatus.Completed;
    }

    return AbTestStatus.Running;
  }

  if (now >= startDate) {
    return AbTestStatus.Running;
  }

  return AbTestStatus.Scheduled;
}

export function getDaysRemaining(abTest: AbTest): number {
  const status = getStatus(abTest);
  const now = new Date();

  if (status === AbTestStatus.Scheduled) {
    const startDate = new Date(abTest.startDate);
    return Math.ceil(
      (startDate.getTime() - now.getTime()) / (1000 * 60 * 60 * 24)
    );
  }

  if (status !== AbTestStatus.Running) return 0;

  const endDate: string | null = abTest.endDate;
  if (!endDate) return -1;

  const daysRemaining = Math.ceil(
    (new Date(endDate).getTime() - now.getTime()) / (1000 * 60 * 60 * 24)
  );

  return Math.max(0, daysRemaining);
}

export function getExperimentTimeStatus(abTest: AbTestView): string {
  switch (abTest.status) {
    case AbTestStatus.Scheduled:
      return `Starts in ${abTest.daysRemaining} days`;
    case AbTestStatus.Running:
      return `${abTest.daysRemaining} days`;
    case AbTestStatus.Completed:
      return "0";
    case AbTestStatus.Cancelled:
    default:
      return "N/A";
  }
}

export function buildAdStackView(
  siteConfig: PrebidSiteConfig,
  {
    testGroup = TestGroup.Control,
  }: {
    testGroup?: TestGroup;
  } = {}
): AdStack {
  return {
    id: siteConfig.id,
    name: siteConfig?.notes || `Ad stack v${siteConfig?.version}`,
    description: siteConfig?.notes || "N/A",
    refreshRate: siteConfig?.adRefreshRate || 0,
    mobileTimeout: siteConfig?.mobileTimeout || 0,
    desktopTimeout: siteConfig?.timeout || 0,
    testGroup,
    configVersion: siteConfig?.version || 0,
    networks: siteConfig?.networks || [],
    identityProviders: siteConfig?.identityProviders || [],
  };
}

export function buildAbTestView(
  abTest: AbTest,
  {
    domains,
    prebidSiteConfigsByVersion,
  }: {
    domains: Map<number, string>;
    prebidSiteConfigsByVersion: Record<string, PrebidSiteConfig>;
  }
): AbTestView {
  const status = getStatus(abTest);
  const daysRemaining = getDaysRemaining(abTest);

  const {
    id,
    name,
    hypothesis,
    startDate,
    endDate,
    createdBy,
    siteId,
    testItems,
    percentageOfTotalTraffic = 0,
  } = abTest;

  const formattedStartDate = new Date(`${startDate}T00:00:00`);
  const formattedEndDate = new Date(`${endDate}T00:00:00`);

  return {
    id,
    name,
    description: hypothesis,
    siteDomain: domains.get(siteId) || "N/A",
    siteId,
    startDate: formattedStartDate,
    endDate: formattedEndDate,
    daysRemaining,
    createdBy,
    status,
    percentageOfTotalTraffic,
    adStacks: testItems.map((testItem) => {
      const { pubfigConfigId } = testItem;

      const config = prebidSiteConfigsByVersion[pubfigConfigId];

      return buildAdStackView(config, {
        testGroup: TestGroup.TreatmentA,
      });
    }),
  };
}

export const getUTCMidnight = (date: Date) => {
  const d = new Date(date);
  // Set to midnight in local time first
  d.setHours(0, 0, 0, 0);
  return new Date(
    Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0)
  );
};

/**
 * Maps column widths dynamically based on headers.
 * Handles both 'key' and 'value' formats.
 */
export const useColumnWidths = (
  headers: {
    key?: string;
    value?: string;
    maxWidth?: string | number;
    minWidth?: string | number;
  }[]
) => {
  return computed(() =>
    Object.fromEntries(
      headers.map((h) => {
        const columnKey = h.key || h.value;

        // Normalize `maxWidth` and `minWidth` to numbers
        const maxWidth = h.maxWidth ? parseInt(h.maxWidth.toString(), 10) : NaN;
        const minWidth = h.minWidth ? parseInt(h.minWidth.toString(), 10) : NaN;

        let finalWidth = !isNaN(maxWidth)
          ? maxWidth - 16
          : !isNaN(minWidth)
          ? minWidth - 16
          : 250; // Default to 250px if both are missing

        finalWidth = Math.max(finalWidth, 0);

        return [columnKey, `${finalWidth}px`];
      })
    )
  );
};
