import config from "../../config/config";
import AbstractRestService from "@/api/abstractRestService";
import type Dashboard from "@/types/Dashboard";

/**
 * Flex dashboard operations
 */
export interface FlexDashboardService {
  /**
   * Get flex dashboard by user id.
   */
  getDashboardByUserId(userId: number): Promise<Dashboard[]>;

  /**
   * Create default flex dashboard
   */
  createFromDefaults(userId: number): Promise<Dashboard>;

  /**
   * reset flex dashboard
   */
  resetDashboard(userId: number, dashboardId: number): Promise<Dashboard>;
}

/**
 * HTTP Flex service implementation
 *
 * @implements {FlexUserService}
 */
export class FlexDashboardServiceRestImpl
  extends AbstractRestService
  implements FlexDashboardService
{
  /**
   * Init
   * @param authRequired
   */
  constructor(authRequired?: boolean) {
    super(config.api.pub_api_base_url, authRequired);
  }

  /**
   * @param userId
   */
  async getDashboardByUserId(userId: number): Promise<Dashboard[]> {
    const client = await this.getRestClient();
    return (await client.get(`flex/full-dash?userId=${userId}`)).data;
  }

  /**
   * @param userId
   */
  async createFromDefaults(userId: number): Promise<Dashboard> {
    const client = await this.getRestClient();
    return (await client.post(`flex/full-dash?userId=${userId}`)).data;
  }

  /**
   * @param userId
   * @param dashboardId
   */
  async resetDashboard(
    userId: number,
    dashboardId: number
  ): Promise<Dashboard> {
    const client = await this.getRestClient();
    return (
      await client.patch(
        `flex/full-dash?userId=${userId}&dashboardId=${dashboardId}`
      )
    ).data;
  }
}

export default new FlexDashboardServiceRestImpl();
