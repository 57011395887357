import { checkToken } from "@/api/tokens";
import Client from "@/api/client";

export default class AbstractRestService {
  /**
   * @protected
   */
  protected authRequired: boolean | undefined;

  /**
   * @protected
   */
  protected url: string;

  /**
   * Init
   * @param url
   * @param authRequired
   */
  constructor(url: string, authRequired?: boolean) {
    this.authRequired = authRequired || false;
    this.url = url;
  }

  /**
   * Get a REST client
   *
   * @param overrideUrl Optional override for the base URL
   * @returns Promise resolving to the client
   */
  protected getRestClient(overrideUrl?: string): Promise<any> {
    const baseUrl = overrideUrl || this.url;

    return new Promise((resolve) => {
      if (this.authRequired) {
        checkToken().then(() => {
          resolve(new Client.ApiClient(baseUrl));
        });
      } else {
        resolve(new Client.ApiClient(baseUrl));
      }
    });
  }

  /**
   * Set authorization check
   *
   * @param value
   */
  public setAuthRequired(value: boolean) {
    this.authRequired = value;
  }
}
