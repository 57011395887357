import { h } from "vue";

const titleEl = h(
  "h1",
  {
    style: {
      fontSize: "32px",
      fontWeight: "700",
      marginBottom: "25px",
    },
  },
  "Dashboard Update in Progress"
);

const errorEl = h(
  "p",
  {
    style: {
      fontSize: "14px",
      fontWeight: "400",
      marginBottom: "25px",
    },
  },
  [
    "The Publisher Dashboard is currently being updated. Please refresh the page or check back in a few minutes.",
  ]
);

const refreshBtnEl = h(
  "button",
  {
    onClick: () => window.location.replace(window.location.href),
    style: {
      color: "#2D2926",
      minWidth: "116px",
      height: "36px",
      backgroundColor: "#00C389",
      borderRadius: "4px",
      fontWeight: "600",
      lineHeight: "20px",
      textTransform: "capitalize",
      boxShadow:
        "0 3px 1px -2px #00000033, 0 2px 2px 0 #00000024, 0 1px 5px 0 #0000001f",
    },
  },
  "refresh"
);

const OutageScreen = h(
  "div",
  {
    style: {
      backgroundColor: "ffffff",
      maxWidth: "460px",
      margin: "45px auto 25px auto",
      textAlign: "center",
      fontFamily: "IBM Plex Sans, Montserrat, Roboto, sans-serif",
    },
  },
  [titleEl, errorEl, refreshBtnEl]
);

export { OutageScreen };
