import type { Commit } from "vuex";
import type AdvancedReport from "@/types/AdvancedReport";
import type CubeFilter from "@/types/thirdParty/cube/CubeFilter";
import type CubeTimeDimension from "@/types/thirdParty/cube/CubeTimeDimension";
import type ReportSchedule from "@/types/ReportSchedule";
import pubApi from "@/api/pub";
import * as types from "./mutation-types";
import dayjs from "dayjs";

interface State {
  reportsLoaded: boolean;
  reports: Array<AdvancedReport>;
  currentReport: AdvancedReport;
  defaultReport: AdvancedReport;
}

const state: State = {
  reportsLoaded: false,
  currentReport: {
    id: 0,
    profileId: 0,
    name: "New Report",
    inventoryType: "",
    reportType: "",
    cubeName: "",
    cubeQuery: {
      measures: [],
      timeDimensions: [],
      dimensions: [],
      // filters: [
      //   {
      //     member: "",
      //     operator: "",
      //     values: [],
      //   },
      // ],
      total: true,
    },
    reportTimezone: "America/Chicago",
    dataVisualization: true,
    archived: false,
  },
  defaultReport: {
    id: 0,
    profileId: 0,
    name: "New Report",
    inventoryType: "",
    reportType: "",
    cubeName: "",
    cubeQuery: {
      measures: [],
      timeDimensions: [],
      dimensions: [],
      // filters: [
      //   {
      //     member: "",
      //     operator: "",
      //     values: [],
      //   },
      // ],
      total: true,
    },
    reportTimezone: "America/Chicago",
    dataVisualization: true,
    createdAt: "2023-01-15",
    updatedAt: "2023-01-15",
    archived: false,
  },
  reports: [
    {
      id: 1,
      profileId: 1,
      name: "My Report",
      inventoryType: "APP",
      reportType: "",
      cubeName: "NdrApp",
      cubeQuery: {
        measures: ["NdrApp.impressions", "NdrApp.gross_revenue"],
        timeDimensions: [
          {
            dimension: "NdrApp.record_date",
            // dateRange: "Last 30 days",
            dateRange: ["2023-02-01", "2023-02-15"],
            granularity: "day",
          },
        ],
        dimensions: ["NdrApp.ad_unit"],
        filters: [
          {
            member: "NdrApp.ad_unit",
            operator: "contains",
            values: ["lost"],
          },
          {
            member: "NdrApp.site_id",
            operator: "equals",
            values: ["128", "130", "131"],
          },
        ],
      },
      reportTimezone: "America/Chicago",
      dataVisualization: true,
      archived: false,
      createdAt: "2023-01-15",
      updatedAt: "2023-01-15",
      schedule: {
        interval: "DAILY",
        scheduleTime: "02:30 pm CST",
        userTimezone: "America/Chicago",
        scheduleDayOfWeek: null,
        scheduleDayOfMonth: null,
        lastRunDateTime: "",
        nextRunDateTime: "",
        emailRecipients: "",
        fileFormat: "CSV",
        fileNameFormat: "DEFAULT",
        zipResults: true,
        includeDisclaimer: true,
        useFullCountryNames: false,
      },
    },
  ],
};

const getters = {
  reports: (state: State) => state.reports,
  reportById: (state: State) => (id: number) =>
    state.reports.find((report) => report.id === id),
  currentReport: (state: State) => state.currentReport,
};

function getNextRunDateTime(report: AdvancedReport) {
  if (report.schedule) {
    // In the case of "ONCE" or no interval, do nothing.
    const now = dayjs();
    const tempDate = dayjs(report.schedule.scheduleTime, "hh:mm a")
      .year(now.year())
      .month(now.month())
      .date(now.date())
      .tz(report.schedule.userTimezone);

    if (report.schedule.interval === "DAILY") {
      // If it's later today that's fine, otherwise push to tomorrow.
      return (
        now.isAfter(tempDate) ? tempDate.add(1, "day") : tempDate
      ).toISOString();
    }
    if (report.schedule.interval === "WEEKLY") {
      const dayOfWeek = [
        "SUNDAY",
        "MONDAY",
        "TUESDAY",
        "WEDNESDAY",
        "THURSDAY",
        "FRIDAY",
        "SATURDAY",
      ].findIndex(
        (i) => i === (report.schedule?.scheduleDayOfWeek ?? "SUNDAY")
      );

      const weeklyNextRun = tempDate.day(dayOfWeek);

      return (
        now.isAfter(weeklyNextRun)
          ? weeklyNextRun.add(1, "week")
          : weeklyNextRun
      ).toISOString();
    }
    if (report.schedule.interval === "MONTHLY") {
      const maxDay = tempDate.daysInMonth();
      const maxDayNextMonth = tempDate.add(1, "month").daysInMonth();
      const newDate = report.schedule.scheduleDayOfMonth ?? now.date();
      const nextRunThisMonth = tempDate.date(Math.min(newDate, maxDay));
      const nextRunNextMonth = tempDate
        .add(1, "month")
        .date(Math.min(newDate, maxDayNextMonth));

      return (
        now.isAfter(nextRunThisMonth) ? nextRunNextMonth : nextRunThisMonth
      ).toISOString();
    }
  }

  return "";
}

const actions = {
  async fetchReports({ commit }: { commit: Commit }, userId: number) {
    const reports = await pubApi.reports.getByUserId(userId);
    // Remove any "site id" filters from reports before storing them
    // These filters are automatically applied when reports are saved
    // and used to ensure that scheduled reports (run with elevated permissions)
    // don't query sites that a user shouldn't have access to
    reports.forEach((report: AdvancedReport) => {
      if (report.cubeQuery.filters) {
        report.cubeQuery.filters = report.cubeQuery.filters.filter(
          (item: CubeFilter) => !item.member.endsWith("site_id")
        );
      }
    });
    commit(
      types.RECEIVE_REPORTS,
      reports.filter((report) => !report.archived)
    );
  },
  async updateReport(
    { commit, state }: { commit: Commit; state: State },
    { userId, report }: { userId: number; report: AdvancedReport }
  ) {
    if (report.schedule) {
      report.schedule.nextRunDateTime = getNextRunDateTime(report);
    }

    const updatedReport = await pubApi.reports.update(userId, report);
    // Remove any "site id" filters from the report before storing it
    // These filters are automatically applied when reports are saved
    // and used to ensure that scheduled reports (run with elevated permissions)
    // don't query sites that a user shouldn't have access to
    if (updatedReport.cubeQuery.filters) {
      updatedReport.cubeQuery.filters = updatedReport.cubeQuery.filters.filter(
        (item: CubeFilter) => !item.member.endsWith("site_id")
      );
    }

    commit(types.RECEIVE_REPORTS, [
      ...state.reports.filter((r) => r.id !== updatedReport.id),
      updatedReport,
    ]);
    commit(types.SET_CURRENT_REPORT, updatedReport);
  },
  async createReport(
    { commit, state }: { commit: Commit; state: State },
    { userId, report }: { userId: number; report: AdvancedReport }
  ) {
    if (report.schedule) {
      report.schedule.nextRunDateTime = getNextRunDateTime(report);
    }

    const createdReport = await pubApi.reports.create(userId, report);
    // Remove any "site id" filters from the report before storing it
    // These filters are automatically applied when reports are saved
    // and used to ensure that scheduled reports (run with elevated permissions)
    // don't query sites that a user shouldn't have access to
    if (createdReport.cubeQuery.filters) {
      createdReport.cubeQuery.filters = createdReport.cubeQuery.filters.filter(
        (item: CubeFilter) => !item.member.endsWith("site_id")
      );
    }

    commit(types.RECEIVE_REPORTS, [...state.reports, createdReport]);
    commit(types.SET_CURRENT_REPORT, createdReport);
  },
  async deleteReport(
    { commit, state }: { commit: Commit; state: State },
    { userId, reportId }: { userId: number; reportId: number }
  ) {
    // In other instances, the site ID filter gets stripped.
    // We want to keep it for API permission reasons, so fetch manually
    // then update with archived = true.
    const reports = await pubApi.reports.getByUserId(userId);

    const reportToDelete = reports.find((i) => i.id === reportId);

    if (!reportToDelete) {
      // Nothing to delete!
      return;
    }

    const updatedReport = await pubApi.reports.update(userId, {
      ...reportToDelete,
      archived: true,
    });
    commit(types.RECEIVE_REPORTS, [
      ...state.reports.filter((r) => r.id !== updatedReport.id),
    ]);
  },
};

const mutations = {
  [types.RECEIVE_REPORTS](state: State, reports: Array<AdvancedReport>) {
    state.reports = reports;
    state.reportsLoaded = true;
  },
  [types.UPDATE_FILTERS](state: State, filters: Array<CubeFilter>) {
    state.currentReport.cubeQuery.filters = filters;
  },
  [types.CLEAR_REPORT](state: State) {
    state.currentReport = JSON.parse(JSON.stringify(state.defaultReport));
  },
  [types.SET_CURRENT_REPORT_BY_ID](state: State, reportId: number) {
    const foundReport = state.reports.find((rep) => rep.id === reportId);
    if (foundReport) {
      state.currentReport = foundReport;
    } else {
      state.currentReport = JSON.parse(JSON.stringify(state.defaultReport));
    }
  },
  [types.SET_CURRENT_REPORT](state: State, report: AdvancedReport) {
    if (report) {
      state.currentReport = report;
    } else {
      state.currentReport = JSON.parse(JSON.stringify(state.defaultReport));
    }
  },
  [types.UPDATE_NAME](state: State, reportName: string) {
    state.currentReport.name = reportName;
  },
  [types.UPDATE_ACCOUNT_ID](state: State, accountId: number) {
    state.currentReport.accountId = accountId;
  },
  [types.UPDATE_CUBE_NAME](state: State, cubeName: string) {
    state.currentReport.cubeName = cubeName;
  },
  [types.UPDATE_REPORT_DATERANGE](
    state: State,
    timeDimension: CubeTimeDimension
  ) {
    state.currentReport.cubeQuery.timeDimensions = [timeDimension];
  },
  [types.UPDATE_INVENTORY_TYPE](state: State, inventoryType: string) {
    state.currentReport.inventoryType = inventoryType;
  },
  [types.UPDATE_REPORT_TYPE](state: State, reportType: string) {
    state.currentReport.reportType = reportType;
  },
  [types.UPDATE_REPORT_MEASURES](state: State, measures: string[]) {
    state.currentReport.cubeQuery.measures = measures;
  },
  [types.UPDATE_REPORT_DIMENSIONS](state: State, dimensions: string[]) {
    state.currentReport.cubeQuery.dimensions = dimensions;
  },
  [types.UPDATE_CURRENT_REPORT_SCHEDULE](
    state: State,
    updatedSchedule: ReportSchedule
  ) {
    state.currentReport.schedule = updatedSchedule;
  },
};

/**
 * @see https://vuex.vuejs.org/en/modules.html
 */
export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
