/* eslint-disable no-shadow,no-param-reassign */

import * as types from "./mutation-types";

const state = {
  navBarOpen: false,
  adBlockWarningDismissed: false,
};

const getters = {
  navBarOpen: (state) => state.navBarOpen,
  adBlockWarningDismissed: (state) => state.adBlockWarningDismissed,
};

const actions = {
  setNavStatus({ commit }, status = false) {
    if (status) {
      commit(types.NAV_BAR_OPENED);
    } else {
      commit(types.NAV_BAR_CLOSED);
    }
  },
  dismissAdBlockWarning({ commit }) {
    commit(types.DISMISS_AD_BLOCK_WARNING);
  },
};

const mutations = {
  [types.NAV_BAR_OPENED](state) {
    state.navBarOpen = true;
  },
  [types.NAV_BAR_CLOSED](state) {
    state.navBarOpen = false;
  },
  [types.DISMISS_AD_BLOCK_WARNING](state) {
    state.adBlockWarningDismissed = true;
  },
};

/**
 * @see https://vuex.vuejs.org/en/modules.html
 */
export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
