import store from "../../store";

const paymentsOnlyUserPages = [
  "payments",
  "publisher.profile",
  "publisher.notifications",
];

/**
 * Global navigation guard to check if a user has access to at least one site, and/or at least one company
 * Company access is needed to access the payments page
 * Site access is needed for all other pages on the pub dashboard
 *
 * A site must be active in order for it to count towards site access
 *
 * Some users will be "payments-only", in which case they can only view pages in the array above
 * Some users will be "reporting-only", in which case they can view everything BUT the payments page
 */
const checkSitesAndCompanies = (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Page requires auth (basically not login or reset-password)
    const hasSites = store.getters["users/sites"].length >= 1;
    const hasCompanies = store.getters["users/companies"].length >= 1;
    const isAdmin = store.getters["users/isAdmin"];
    const canAccessAllPages = isAdmin || (hasSites && hasCompanies);
    if (canAccessAllPages) {
      return next();
    } else if (hasCompanies) {
      // non admin, has company access
      if (paymentsOnlyUserPages.indexOf(to.name) > -1) {
        // allowed to access requested page
        return next();
      }
      // requested page not allowed, redirect to payments page
      return next({ name: "payments" });
    } else if (hasSites) {
      // non admin, has site access but NOT company access
      if (to.name === "payments") {
        // requested payments page, redirect them to the dashboard
        return next({ name: "dashboard" });
      }
      // allowed to access requested page
      return next();
    }
    // no sites or companies
    return next(false);
  }
  // requested page doesn't require auth, allow access
  return next();
};

export default checkSitesAndCompanies;
