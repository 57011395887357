export const SIGN_OUT = "SIGN_OUT";
export const SIGN_IN_REQUEST = "SIGN_IN_REQUEST";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAILURE = "SIGN_IN_FAILURE";
export const CLEAR_SIGN_IN_FAILURE = "CLEAR_SIGN_IN_FAILURE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const SET_INTENDED_ROUTE = "SET_INTENDED_ROUTE";
export const CLEAR_INTENDED_ROUTE = "CLEAR_INTENDED_ROUTE";
export const SET_IMPERSONATION = "SET_IMPERSONATION";
export const CLEAR_IMPERSONATION = "CLEAR_IMPERSONATION";
export const PARSE_HASH_SUCCESS = "PARSE_HASH_SUCCESS";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const GET_EMAIL_SUCCESS = "GET_EMAIL_SUCCESS";
export const SET_CURRENT_ACCOUNT = "SET_CURRENT_ACCOUNT";
export const SET_PREVIOUS_ACCOUNT = "SET_CURRENT_ACCOUNT";
export const RECEIVE_USERS = "RECEIVE_USERS";
export const ALL_LOADED = "ALL_LOADED";
