import { ref, watch } from "vue";
import { defineStore } from "pinia";
import prebidApi from "@/api/prebid";
import pubfigApi from "@/api/pubfig";
import { TestGroup, type AbTestView } from "@/types/AbTest";

import { usePrebidStore } from "./prebid";
import { useAbTestStore } from "./abTests";
import { buildAbTestView, buildAdStackView } from "@/lib/flex/abTestUtils";

export const useAbTestViewStore = defineStore("abTestView", {
  state: () => ({
    isLoading: false,
    error: null as Error | null,
    errorCode: null,
    data: null as AbTestView | null,
  }),
  actions: {
    async fetchAbTestView({ experimentId }: { experimentId: string }) {
      if (this.data?.id === experimentId) {
        return;
      }

      this.isLoading = true;
      this.error = null;

      const accountAbTests = useAbTestStore();
      const prebidStore = usePrebidStore();

      // Fetch is triggered by router: src/router/index.ts
      if (accountAbTests.loading) {
        await new Promise((resolve) => {
          watch(accountAbTests, (data) => {
            if (!data.loading) {
              resolve(null);
            }
          });
        });
      }

      if (!accountAbTests.abTests.length) {
        return;
      }

      this.isLoading = false;
      this.error = null;

      const abTest = accountAbTests.abTests.find(
        (abTest) => abTest.id === experimentId
      );

      if (!abTest) {
        this.error = new Error("Experiment not found");
        return;
      }

      this.data = buildAbTestView(abTest, {
        domains: accountAbTests.domains,
        prebidSiteConfigsByVersion:
          prebidStore.currentPrebidSiteConfigsByVersion,
      });

      const { control } = abTest;

      if (control?.deploymentId) {
        this.fetchControlAdStack(control.deploymentId);
      }
    },
    async fetchControlAdStack(deploymentId: string) {
      this.isLoading = true;
      this.error = null;

      try {
        const controlDeployment = await pubfigApi.getDeployment(deploymentId);

        if (!controlDeployment.configVersionId) {
          throw new Error(
            "Control deployment does not have a config version. The deployment is legacy or config does not exist."
          );
        }

        const controlConfig = await prebidApi.getVersion(
          controlDeployment.configVersionId
        );

        if (!controlConfig) {
          throw new Error("Failed to fetch control config");
        }

        this.data?.adStacks.unshift(
          buildAdStackView(controlConfig, { testGroup: TestGroup.Control })
        );
      } catch (err) {
        this.error = err as Error;
      } finally {
        this.isLoading = false;
      }
    },
  },
});
