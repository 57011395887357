import axios from "axios";
import type { AxiosInstance } from "axios";
import raven from "../lib/helpers/raven";
import store from "../store/index";
import config from "../config/config";

/**
 * Create a new Axios client instance
 * @see https://github.com/mzabriskie/axios#creating-an-instance
 */
const getClient = (
  baseUrl: string | null = null,
  authHeader: boolean = true
) => {
  raven.captureBreadcrumb("axios", "getClient", "api", {});
  const options = {
    baseURL: baseUrl === null ? config.api.base_url : baseUrl,
    headers: {},
  };

  if (store.getters["users/isAuthenticated"]) {
    options.headers = {
      Authorization: authHeader
        ? `Bearer ${store.getters["users/accessToken"]}`
        : "",
    };
  }

  const client: AxiosInstance = axios.create(options);

  // Add a request interceptor
  client.interceptors.request.use(
    (requestConfig) => {
      // if (!requestConfig.url.includes('public')) requestConfig.headers.authorization = `Bearer ${store.getters['users/accessToken']}`;
      return requestConfig;
    },
    (requestError) => {
      raven.captureException(requestError);

      return Promise.reject(requestError);
    }
  );

  // Add a response interceptor
  client.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status >= 500) {
        raven.captureException(error);
      }

      return Promise.reject(error);
    }
  );

  return client;
};

class ApiClient {
  client: AxiosInstance;
  constructor(baseUrl: string | null = null, authHeader: boolean = true) {
    this.client = getClient(baseUrl, authHeader);
  }

  get(url: string, conf = {}) {
    return this.client
      .get(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }

  delete(url: string, conf = {}) {
    return this.client
      .delete(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }

  head(url: string, conf = {}) {
    return this.client
      .head(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }

  options(url: string, conf = {}) {
    return this.client
      .options(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }

  post(url: string, data = {}, conf = {}) {
    return this.client
      .post(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }

  put(url: string, data = {}, conf = {}) {
    return this.client
      .put(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }

  patch(url: string, data = {}, conf = {}) {
    return this.client
      .patch(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }

  cancelToken() {
    return axios.CancelToken.source();
  }
}

/**
 * Base HTTP Client
 */
export default {
  // Expose the configurable client to configure individual services
  ApiClient,

  // Provide request methods with the default base_url
  get(url: string, conf = {}) {
    return getClient()
      .get(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  delete(url: string, conf = {}) {
    return getClient()
      .delete(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  head(url: string, conf = {}) {
    return getClient()
      .head(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  options(url: string, conf = {}) {
    return getClient()
      .options(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  post(url: string, data = {}, conf = {}) {
    return getClient()
      .post(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  put(url: string, data = {}, conf = {}) {
    return getClient()
      .put(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  patch(url: string, data = {}, conf = {}) {
    return getClient()
      .patch(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  cancelToken() {
    return axios.CancelToken.source();
  },
};
