import Raven from "raven-js";

export default {
  captureBreadcrumb(component, message, category, data) {
    Raven.captureBreadcrumb({
      message,
      category,
      data: {
        component,
        ...data,
      },
    });
  },
  wrap(config, func) {
    Raven.wrap(config, func);
  },
  captureException(e) {
    Raven.captureException(e);
  },
};
