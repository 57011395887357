import { ref, computed, type Ref } from "vue";
import { defineStore } from "pinia";
import { default as prebidService } from "@/api/prebid";
import type {
  PrebidSiteConfig,
  PrebidSiteConfigRecord,
  PrebidSiteConfigVersion,
  PrebidSiteConfigVersionsRecord,
} from "@/types/PrebidSiteConfig";

/**
 * Prebid store
 * @link
 */
export const usePrebidStore = defineStore("prebid", () => {
  // States
  const siteId = ref<number | undefined>();
  const currentPrebidSiteConfigsState = ref<PrebidSiteConfigRecord>({});
  const currentPrebidSiteConfigVersionsState =
    ref<PrebidSiteConfigVersionsRecord>({});
  const currentPrebidSiteConfigState = ref<PrebidSiteConfig | undefined>();
  const currentPrebidSiteConfigsByVersionState = ref<
    Record<string, PrebidSiteConfig>
  >({});

  // Computed
  const currentPrebidSiteConfigs = computed<PrebidSiteConfigRecord>(
    () => currentPrebidSiteConfigsState.value
  );
  const currentPrebidSiteConfigVersions =
    computed<PrebidSiteConfigVersionsRecord>(
      () => currentPrebidSiteConfigVersionsState.value
    );
  const currentPrebidSiteConfigVersionsForSite = computed(() => {
    return (siteId: Ref<number | undefined>) => {
      if (!siteId.value) return [];
      const id = siteId.value;
      if (!(id in currentPrebidSiteConfigVersionsState.value)) return [];
      return currentPrebidSiteConfigVersionsState.value[id];
    };
  });

  const currentPrebidSiteConfig = computed<PrebidSiteConfig | undefined>(
    () => currentPrebidSiteConfigState.value
  );

  const currentPrebidSiteConfigsByVersion = computed<
    Record<string, PrebidSiteConfig>
  >(() => currentPrebidSiteConfigsByVersionState.value);

  const currentPrebidSiteConfigName = computed(() => {
    const config = currentPrebidSiteConfigState.value;
    if (!config) return "";
    return config.adStackName || `Unnamed (${config.id})`;
  });

  async function getPrebidSiteConfigBySiteIds(
    siteIds: number[]
  ): Promise<void> {
    for (const siteId of siteIds) {
      if (!currentPrebidSiteConfigsState.value[siteId]) {
        prebidService
          .getPrebidConfigBySiteId(siteId)
          .then((newPrebidSiteConfig) => {
            currentPrebidSiteConfigsState.value[siteId] = newPrebidSiteConfig;
          });
      }
    }
  }

  async function getPrebidSiteConfigVersionsBySiteId(
    siteId: number
  ): Promise<void> {
    if (!currentPrebidSiteConfigVersionsState.value[siteId]) {
      await prebidService
        .getPrebidConfigVersionsBySiteId(siteId)
        .then((newVersions) => {
          currentPrebidSiteConfigVersionsState.value[siteId] = newVersions;
        });
    }
  }

  async function getPrebidSiteConfigBySiteId(
    siteId: number,
    doUpdateState: boolean = true
  ): Promise<PrebidSiteConfig> {
    return prebidService.getPrebidConfigBySiteId(siteId).then((config) => {
      if (doUpdateState) {
        currentPrebidSiteConfigsState.value[siteId] = config;
        updateCurrentPrebidSiteConfigState(config);
      }
      return Promise.resolve(config);
    });
  }

  // No filtering by source needed here as a specific version is being requested by ID
  async function getPrebidSiteConfigVersion(
    versionId: string
  ): Promise<PrebidSiteConfig> {
    return prebidService.getVersion(versionId).then((version) => {
      updateCurrentPrebidSiteConfigState(version);
      return Promise.resolve(version);
    });
  }

  async function activatePrebidSiteConfigVersion(
    versionId: string,
    doUpdateState: boolean = true
  ): Promise<PrebidSiteConfig> {
    return prebidService
      .activateVersion(versionId)
      .then((prebidSiteConfig: PrebidSiteConfig) => {
        if (doUpdateState) {
          currentPrebidSiteConfigsState.value[prebidSiteConfig.siteId] =
            prebidSiteConfig;
          updateCurrentPrebidSiteConfigState(prebidSiteConfig);
        }
        return Promise.resolve(prebidSiteConfig);
      });
  }

  function setActivePrebidSiteConfigsState(
    prebidSiteConfig: PrebidSiteConfig,
    siteId: number
  ) {
    updateCurrentPrebidSiteConfigState(prebidSiteConfig);
    currentPrebidSiteConfigsState.value[siteId] = prebidSiteConfig;
  }

  function setActivePrebidSiteConfigsStateBySiteId(
    prebidSiteConfig: PrebidSiteConfig,
    siteId: number
  ) {
    currentPrebidSiteConfigsState.value[siteId] = prebidSiteConfig;
  }

  async function createPrebidSiteConfig(
    siteId: number,
    body: PrebidSiteConfig,
    isActive: boolean
  ): Promise<PrebidSiteConfig> {
    return prebidService
      .create(siteId, body, isActive)
      .then((newPrebidConfig) => {
        // Updating the current prebid site config
        updateCurrentPrebidSiteConfigState(newPrebidConfig);

        if (isActive) {
          // Updating the current prebid site config for the site
          currentPrebidSiteConfigsState.value[siteId] = newPrebidConfig;
        }

        // Adding a new version
        if (currentPrebidSiteConfigVersionsState.value[siteId]) {
          const newVar: PrebidSiteConfigVersion = {
            id: newPrebidConfig.id,
            adStackName: newPrebidConfig.adStackName,
            createdAt: newPrebidConfig.createdAt,
            createdBy: newPrebidConfig.createdBy,
            version: newPrebidConfig.version,
            notes: newPrebidConfig.notes,
            abTestConfig: newPrebidConfig.abTestConfig,
            source: "FLEX",
          };
          currentPrebidSiteConfigVersionsState.value[siteId] = [
            newVar,
            ...currentPrebidSiteConfigVersionsState.value[siteId],
          ];
        }

        return newPrebidConfig;
      });
  }

  function updateCurrentPrebidSiteConfigState(version: PrebidSiteConfig): void {
    currentPrebidSiteConfigState.value = version;
  }

  // No filtering by source needed here as a specific version is being requested by ID
  async function getPrebidSiteConfigsByVersions(
    versions: string[]
  ): Promise<void> {
    const configPromises = versions.map(async (configVersionId) => {
      if (!configVersionId && configVersionId.length > 0) {
        console.error(
          `ConfigVersionId is null for configVersionId: ${configVersionId}`
        );
        throw Error(
          `ConfigVersionId is null for configVersionId: ${configVersionId}`
        );
      }

      try {
        return await prebidService.getVersion(configVersionId);
      } catch (error) {
        console.error(
          `Failed to fetch config for configVersionId: ${configVersionId}`,
          error
        );
        throw error;
      }
    });

    const sitesConfigs = await Promise.all(configPromises);

    currentPrebidSiteConfigsByVersionState.value = sitesConfigs.reduce<
      Record<string, PrebidSiteConfig>
    >((acc, siteConfig) => {
      acc[siteConfig.id] = siteConfig;
      return acc;
    }, {});
  }

  async function getPrebidConfigBySiteId(siteId: number): Promise<void> {
    try {
      const prebidConfig = await prebidService.getPrebidConfigBySiteId(siteId);
      updateCurrentPrebidSiteConfigState(prebidConfig);
    } catch (error) {
      console.error(
        `Failed to fetch Prebid Config for siteId: ${siteId}`,
        error
      );
    }
  }

  return {
    currentPrebidSiteConfigsState,
    currentPrebidSiteConfigVersionsState,
    currentPrebidSiteConfigState,
    currentPrebidSiteConfigs,
    currentPrebidSiteConfigVersions,
    currentPrebidSiteConfigVersionsForSite,
    currentPrebidSiteConfig,
    getPrebidSiteConfigBySiteId,
    getPrebidSiteConfigBySiteIds,
    getPrebidSiteConfigVersionsBySiteId,
    getPrebidSiteConfigVersion,
    activatePrebidSiteConfigVersion,
    createPrebidSiteConfig,
    updateCurrentPrebidSiteConfigState,
    getPrebidSiteConfigsByVersions,
    getPrebidConfigBySiteId,
    currentPrebidSiteConfigsByVersion,
    currentPrebidSiteConfigName,
    setActivePrebidSiteConfigsState,
    siteId,
    setActivePrebidSiteConfigsStateBySiteId,
  };
});
