export const UPDATE_FILTERS = "UPDATE_FILTERS";
export const SET_CURRENT_REPORT_BY_ID = "SET_CURRENT_REPORT_BY_ID";
export const SET_CURRENT_REPORT = "SET_CURRENT_REPORT";
export const UPDATE_NAME = "UPDATE_NAME";
export const UPDATE_CUBE_NAME = "UPDATE_CUBE_NAME";
export const UPDATE_CURRENT_REPORT_SCHEDULE = "UPDATE_CURRENT_REPORT_SCHEDULE";
export const CLEAR_REPORT = "CLEAR_REPORT";
export const UPDATE_INVENTORY_TYPE = "UPDATE_INVENTORY_TYPE";
export const UPDATE_REPORT_TYPE = "UPDATE_REPORT_TYPE";
export const UPDATE_REPORT_MEASURES = "UPDATE_REPORT_MEASURES";
export const UPDATE_REPORT_DIMENSIONS = "UPDATE_REPORT_DIMENSIONS";
export const UPDATE_REPORT_DATERANGE = "UPDATE_REPORT_DATERANGE";
export const RECEIVE_REPORTS = "RECEIVE_REPORTS";
export const DELETE_REPORT = "DELETE_REPORT";
export const UPDATE_ACCOUNT_ID = "UPDATE_ACCOUNT_ID";
