<template>
  <v-overlay
    :model-value="inProgress"
    class="align-center justify-center"
    :close-on-back="false"
    :close-on-content-click="false"
    :persistent="true"
    :opacity="0.9"
    scrim="#ffffff"
  >
    <div class="loading-overlay__content d-flex flex-column align-center ga-2">
      <v-progress-circular color="primary" size="64" indeterminate />
      <slot name="message"> loading... </slot>
    </div>
  </v-overlay>
</template>

<script setup lang="ts">
import { useStore } from "vuex";
import { computed } from "vue";

/**
 * Global store
 */
const store = useStore();

/**
 * Retrieve progress status. Currently, this overlay is used when
 * switching accounts, but it can also be used for other events.
 */
const inProgress = computed(() => store.getters["accounts/switchingAcccount"]);
</script>
