import { computed } from "vue";
import { useStore } from "vuex";
import type { FlexSite } from "@/types/Flex";

export function useAccessChecks() {
  const store = useStore();

  const sitesByAccount = computed<FlexSite[]>(
    () => store.getters["accounts/flexSitesForAccount"]
  );

  const isOnboardingOpenAndPodIdExists = computed(() => {
    return sitesByAccount.value.some(
      (s: FlexSite) => Boolean(s.podId) && s.onboardingOpen
    );
  });

  return {
    isOnboardingOpenAndPodIdExists,
  };
}
