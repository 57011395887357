import Client from "./client";
import config from "../config/config";
import { checkToken } from "./tokens";

let pubfigV1Client;
let pubfigClient;

/**
 * Lazy-loaded method to get an API client at runtime
 * @return {client.ApiClient}
 */
const getClient = (authRequired = true) =>
  new Promise((resolve) => {
    if (authRequired) {
      checkToken().then(() => {
        pubfigClient = new Client.ApiClient(config.api.base_url_v2);
        // pubfigClient = new Client.ApiClient('http://localhost:3011/api/v2');
        resolve(pubfigClient);
      });
    } else {
      pubfigClient = new Client.ApiClient(config.api.base_url_v2);
      // pubfigClient = new Client.ApiClient('http://localhost:3011/api/v2');
      resolve(pubfigClient);
    }
  });

/**
 * Lazy-loaded method to get an API client at runtime
 * @return {client.ApiClient}
 */
const getV1Client = (authRequired = true) =>
  new Promise((resolve) => {
    if (authRequired) {
      checkToken().then(() => {
        pubfigV1Client = new Client.ApiClient(config.api.base_url_v1);
        resolve(pubfigV1Client);
      });
    } else {
      pubfigV1Client = new Client.ApiClient(config.api.base_url_v1);
      resolve(pubfigV1Client);
    }
  });

export default {
  /**
   * Get pubfig deployments for a site
   * @param siteId The site id
   */
  getDeployments(siteId) {
    return getClient()
      .then((client) =>
        client.get("pubfig/deployments", { params: { siteId } })
      )
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  checkTemplateVersions(templateVersionIds, minReleaseDate) {
    const path = `pubfig/templates/checkTemplateVersions?versionIds=${templateVersionIds}&minReleaseDate=${minReleaseDate}`;
    return getV1Client()
      .then((client) => client.get(path))
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  getDeploymentsNoScripts(siteId = null, limit = 0, offset = 0, env = null) {
    let path = "pubfig/deployments/noScripts?";
    if (siteId) {
      path += `siteId=${siteId}&limit=${limit}&offset=${offset}`;
    }
    if (env) {
      path += `${siteId ? "&" : ""}env=${env}`;
    }

    return getClient()
      .then((client) => client.get(path))
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  getProductionDeployment(siteId) {
    const path = `pubfig/deployments/productionDeployment?siteId=${siteId}`;
    return getClient()
      .then((client) => client.get(path))
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  getSlcDeployments(siteId) {
    const path = `pubfig/deployments/slc?siteId=${siteId}`;
    return getClient()
      .then((client) => client.get(path))
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  getDeployment(deploymentId) {
    return getClient()
      .then((client) =>
        client.get(`pubfig/deployments/${deploymentId}?id=${deploymentId}`)
      )
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Create a new pubfig deployment
   * @param body
   */

  alertSlackStart(user, deployType, env) {
    return getClient()
      .then((client) =>
        client.post("pubfig/deployments/slackAlert", {
          text: `${user} started an ${deployType} deploy to ${env}`,
        })
      )
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  alertSlackFinish(
    user,
    deployType,
    env,
    altConfigId,
    comment,
    templateId,
    sortedTemplates
  ) {
    let templateName = "";
    sortedTemplates.forEach((element) =>
      element.versions.forEach((version) => {
        if (version.id === templateId) {
          templateName = version.notes;
        }
      })
    );
    let message = "";
    if (env === "TEST") {
      message = `Finished ${deployType} deploy to ${env} for template '${templateName}' altConfig ${altConfigId} with the comment - '${comment}'`;
    } else {
      message = `Finished ${deployType} deploy to ${env} for template '${templateName}' with the comment - '${comment}'`;
    }

    return getClient()
      .then((client) =>
        client.post("pubfig/deployments/slackAlert", { text: message })
      )
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  createDeployment(body) {
    // contextJsonBase64 deprecated in favor of artifactsLocation.
    const reqParams = { includeContextJsonBase64: false };
    if (body.configVersion) {
      reqParams.prebidVersion = body.configVersion;
    }

    return getClient()
      .then((client) => {
        if (body.siteType === "APP") {
          return client.post("pubfig/deployments/app", body, {
            params: reqParams,
          });
        }
        return client.post("pubfig/deployments", body, { params: reqParams });
      })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  // For purging CF cached versions of pubfig upon deployment of new versions
  purgeFile(file) {
    return getClient()
      .then((client) =>
        client.post("pubfig/deployments/purgeFiles", file, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
      )
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  purgeAll(anodotAlert) {
    return getClient()
      .then((client) =>
        client.post(
          `pubfig/deployments/purgeFiles?purgeAll=true&anodotAlert=${anodotAlert}`,
          ""
        )
      )
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  /**
   * retrieve all tests
   * @param {Object} params paging/sorting/filtering options
   * @param params.siteId optional filter results by site id
   * @param params.page optional page of results to return, default 1
   * @param params.limit optional number of results in one page, default 20
   * @param params.query optional string to filter results by name, created_by, hypothesis, methodology, site name, publisher name
   * @param params.sortBy optional default start_date
   * @param params.sortDir optional asc or desc
   * @returns {Promise<Object>} Object with tests and paging/sorting/filtering metadata
   */
  async getAllTests({
    siteId = 0,
    page = 1,
    limit = 20,
    query = "",
    sortBy = "start_date",
    sortDir = "desc",
  }) {
    const client = await getClient();
    // the server pages are 0 indexed, so we subtract one since elementui pagination starts at 1
    const result = (
      await client.get("pubfig/abTest", {
        params: {
          siteId,
          page: page - 1,
          limit,
          query,
          sortBy,
          sortDir,
        },
      })
    ).data;
    // increment the returned page by one to conform with elementui
    result.page += 1;
    return result;
  },
  /**
   * get a test
   * @param {String} id
   * @returns {Promise<Object>} returned test
   */
  async getTest(id) {
    const client = await getClient();
    return (await client.get(`pubfig/abTest/${id}`)).data;
  },
  /**
   * create a test using the given testData.
   * @param testData
   * @returns {Promise<Object>} Created test
   */
  createTest(testData) {
    return getClient()
      .then((client) => client.post("pubfig/abTest", testData))
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  /**
   * stop a running or scheduled test
   * @param testId
   * @returns {Promise<void>}
   */
  stopTest(testId) {
    return getClient()
      .then((client) => client.put(`pubfig/abTest/${testId}/stop`))
      .then(() => Promise.resolve())
      .catch((error) => Promise.reject(error));
  },
  /**
   * obtain a confirmation code and list of operations to be performed when promoting a
   * test pubfig settings version to production
   * @param testId
   * @returns Promise<Object>
   */
  promoteTestPreflight(testId) {
    return getClient()
      .then((client) => client.get(`pubfig/abTest/${testId}/promote/preflight`))
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  /**
   * request the server to deploy the given test item to production.
   * needs confirmation code from promoteTestPreflight
   * @param testId
   * @param confirmation,
   * @param createdBy
   * @returns {Promise<Object>}
   */
  promoteTest(testId, confirmation, createdBy) {
    return getClient()
      .then((client) =>
        client.put(`pubfig/abTest/${testId}/promote`, {
          confirmation,
          createdBy,
        })
      )
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  /**
   * create a test collection using the given testData.
   * @param testData
   * @returns {Promise<Object>} Created test
   */
  createTestCollection(testData) {
    return getClient()
      .then((client) => client.post("pubfig/abTestCollection", testData))
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Add a site to a test collection.
   * @param id testCollectionId
   * @param testData contains siteId
   * @returns {Promise<Object>} Created test
   */
  addTestToCollection(id, testData) {
    return getClient()
      .then((client) =>
        client.post(`pubfig/abTestCollection/${id}/add`, testData)
      )
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  /**
   * retrieve all test collections
   * @returns {Promise<Array>} List of tests
   */
  getAllTestCollections() {
    return getClient()
      .then((client) => client.get("pubfig/abTestCollection"))
      .then((response) => Promise.resolve(response.data.abTestCollections))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Stop all the tests within a collection
   */
  stopTestCollection(id) {
    return getClient()
      .then((client) => client.put(`pubfig/abTestCollection/${id}/stop`))
      .then(() => Promise.resolve())
      .catch((error) => Promise.reject(error));
  },
};
