import type { AxiosInstance } from "axios";
import { checkToken } from "@/api/tokens";
import Client from "@/api/client";
import config from "@/config/config";
import sentry from "@/lib/helpers/sentry";
import type Dashboard from "@/types/Dashboard";
import type Profile from "@/types/Profile";
import type AdvancedReport from "@/types/AdvancedReport";
import type {
  AdBlockRecoveryConfig,
  PageableAdBlockRecoveryPartialConfig,
} from "@/types/AdBlockRecoveryConfig";
import type { AdBlockRecoveryLanguage } from "@/types/AdBlockRecoveryConfig";

let pubApiClient;
/**
 * Lazy-loaded method to get an API client at runtime
 * @return {client.ApiClient}
 */
const getClient = async (authRequired = true) => {
  if (authRequired) {
    await checkToken();
    pubApiClient = new Client.ApiClient(config.api.pub_api_base_url);
    // pubApiClient = new Client.ApiClient("http://127.0.0.1:8080/pub-api/v1/");
    return pubApiClient;
  }
  pubApiClient = new Client.ApiClient(config.api.pub_api_base_url);
  // pubApiClient = new Client.ApiClient("http://127.0.0.1:8080/pub-api/v1/");
  return pubApiClient;
};

const captureBreadcrumb = (message: string, data?: Object): void => {
  sentry.captureBreadcrumb("pubApiClient", message, "API Call", data || {});
};

export default {
  profiles: {
    async getAll(): Promise<Profile[]> {
      captureBreadcrumb("profiles.getByAll()");
      const client = await getClient();
      return (await client.get("profiles")).data;
    },
    async getByUserId(userId: number): Promise<Profile> {
      captureBreadcrumb("profiles.getByUserId()");
      const client = await getClient();
      return (await client.get(`profiles?userId=${userId}`)).data;
    },
    async create(userId: number, data: Profile): Promise<Profile> {
      captureBreadcrumb("profiles.create()");
      const client = await getClient();
      return (await client.post(`profiles?userId=${userId}`, data)).data;
    },
    async updateByUserId(userId: number, data: any): Promise<Profile> {
      captureBreadcrumb("profiles.updateByUserId()", data);
      const client = await getClient();
      return (await client.put(`profiles?userId=${userId}`, data)).data;
    },
  },
  dashboards: {
    // full dashboard including tiles
    async getAllByUserId(userId: number): Promise<Dashboard[]> {
      captureBreadcrumb("dashboards.getAllByUserId()");
      const client = await getClient();
      return (await client.get(`full-dash?userId=${userId}`)).data;
    },
    async createFromDefaults(userId: number): Promise<Dashboard> {
      captureBreadcrumb("dashboards.createFromDefaults()");
      const client = await getClient();
      return (await client.post(`full-dash?userId=${userId}`)).data;
    },
    async updateWithTiles(userId: number, data: Dashboard): Promise<Dashboard> {
      captureBreadcrumb("dashboards.updateWithTiles()", data);
      const client = await getClient();
      return (await client.put(`full-dash?userId=${userId}`, data)).data;
    },
    async resetDashboard(
      userId: number,
      dashboardId: number
    ): Promise<Dashboard> {
      captureBreadcrumb("dashboards.resetDashboard()");
      const client = await getClient();
      return (
        await client.patch(
          `full-dash?userId=${userId}&dashboardId=${dashboardId}`
        )
      ).data;
    },
  },
  tiles: {
    getAllByProfileIdAndDashboardId(profileId: number, dashboardId: number) {
      captureBreadcrumb("tiles.getAllByProfileIdAndDashboardId()");
      return getClient()
        .then((client) =>
          client.get(`profiles/${profileId}/dashboards/${dashboardId}/tiles`)
        )
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
    },
    getByid(id: number) {},
    updateById(id: number) {},
    create(data: any) {},
    archiveById(id: number) {},
  },
  reports: {
    getByUserId(userId: number): Promise<AdvancedReport[]> {
      captureBreadcrumb("reports.getByUserId()");
      return getClient()
        .then((client) => client.get(`reports?userId=${userId}`))
        .then((response) => Promise.resolve(response.data.reports))
        .catch((error) => Promise.reject(error));
    },
    update(userId: number, data: AdvancedReport): Promise<AdvancedReport> {
      captureBreadcrumb("reports.update()");
      return getClient()
        .then((client) => client.put(`reports?userId=${userId}`, data))
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
    },
    create(userId: number, data: AdvancedReport): Promise<AdvancedReport> {
      captureBreadcrumb("reports.create()");
      return getClient()
        .then((client) => client.post(`reports?userId=${userId}`, data))
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
    },
  },
  adBlockRecovery: {
    getConfigsByAccountId(
      accountId: number
    ): Promise<PageableAdBlockRecoveryPartialConfig> {
      captureBreadcrumb("adBlockRecovery.getConfigsByAccountId()");
      return getClient()
        .then((client) => client.get(`abr/accounts/${accountId}/configs`))
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
    },
    getConfigBySiteId(siteId: number): Promise<AdBlockRecoveryConfig> {
      captureBreadcrumb("adBlockRecovery.getConfigBySiteId()");
      return getClient()
        .then((client) => client.get(`abr/sites/${siteId}/configs`))
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
    },
    getDefaultConfig(): Promise<AdBlockRecoveryConfig> {
      captureBreadcrumb("adBlockRecovery.getDefaultConfig()");
      return getClient()
        .then((client) => client.get("abr/defaults/configs"))
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
    },
    create(
      siteId: number,
      data: AdBlockRecoveryConfig
    ): Promise<AdBlockRecoveryConfig> {
      captureBreadcrumb("adBlockRecovery.create()");
      return getClient()
        .then((client) => client.post(`abr/sites/${siteId}/configs`, data))
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
    },
    update(
      siteId: number,
      data: AdBlockRecoveryConfig
    ): Promise<AdBlockRecoveryConfig> {
      captureBreadcrumb("adBlockRecovery.update()");
      return getClient()
        .then((client) => client.put(`abr/sites/${siteId}/configs`, data))
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
    },
    publish(
      siteId: number,
      environment: "PREVIEW" | "PRODUCTION"
    ): Promise<number> {
      captureBreadcrumb("adBlockRecovery.publish()");
      return getClient()
        .then((client) =>
          client.post(`abr/sites/${siteId}/configs/publish`, { environment })
        )
        .then((response) => Promise.resolve(response.status))
        .catch((error) => Promise.reject(error));
    },
    getScript(
      environment: "PREVIEW" | "PRODUCTION",
      sitePath?: string
    ): Promise<string> {
      captureBreadcrumb("adBlockRecovery.getScript()");
      let queryParams = `?environment=${environment}`;
      if (sitePath) {
        queryParams = queryParams.concat(`&sitePath=${sitePath}`);
      }
      return getClient()
        .then((client) => client.get(`abr/public/scripts${queryParams}`))
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
    },
    getLanguages(): Promise<AdBlockRecoveryLanguage[]> {
      captureBreadcrumb("adBlockRecovery.getLanguages()");
      return getClient()
        .then((client) => client.get("abr/languages"))
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
    },
  },
};
