<template>
  <v-dialog v-model="value" max-width="640" class="impersonate-user-dialog">
    <v-card>
      <v-card-title class="py-3 px-6">
        <v-row class="justify-space-between" no-gutters>
          <v-col cols="auto">
            <span class="text__section"> Impersonate User </span>
          </v-col>
          <v-col cols="auto">
            <v-icon icon="mdi-close" @click="onCancel" size="20"></v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text
        class="border-t-sm border-b-sm px-6 py-4"
        v-loading="working"
        element-loading-text="Loading..."
      >
        <v-row>
          <v-col cols="12">
            <p>
              This feature enables the admin to 'Impersonate' a user and view
              the dashboard from their perspective without needing to sign in as
              that user.
            </p>
            <v-form class="mt-3 user-form">
              <div class="d-flex align-center">
                <v-radio-group
                  v-model="selectedImpersonateOption"
                  inline
                  density="compact"
                  color="primary"
                  class="flex-grow-0 user-radio-btn"
                  hide-details
                >
                  <v-radio label="Me" value="me" />
                  <v-radio label="Another user" value="another" />
                </v-radio-group>
                <v-chip
                  size="small"
                  color="primary"
                  class="ml-3"
                  v-if="impersonatedUser?.isFlex"
                >
                  Flex user
                </v-chip>
              </div>
            </v-form>
          </v-col>
        </v-row>
        <v-row class="mt-2">
          <v-col cols="12" v-if="selectedImpersonateOption === 'another'">
            <div class="vue-select-wrapper position-fixed left-0 w-100 px-4">
              <vue-select
                :options="users"
                v-model="selection"
                label="title"
                placeholder="Search active users..."
                :filterable="false"
                :get-option-label="
                  (u) => `${u.name} - ${u.email} ${meId == u.id ? '(me)' : ''}`
                "
                @search="fetchProfiles"
                @open="onOpen"
                @close="onClose"
              >
                <template #option="{ id, name, email }">
                  <span
                    class="user-item"
                    :class="{ 'user-item--me': meId == id }"
                  >
                    {{ name }}
                    <span v-if="meId == id"> (me)</span> -
                    <span class="user-item__email">{{ email }}</span>
                  </span>
                </template>
                <template #list-footer>
                  <li
                    v-show="hasNextPage"
                    ref="load"
                    class="text-center text-medium-emphasis"
                  >
                    Loading...
                  </li>
                </template>
              </vue-select>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn variant="text" @click="onCancel">Cancel</v-btn>
        <v-btn
          variant="flat"
          color="primary"
          @click="onContinue"
          :disabled="!canContinue"
        >
          Continue
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";
import store from "@/store";
import apiUsers from "@/api/users";
import debounce from "lodash/debounce";
import pubApi from "@/api/pub";
import { UsersStore } from "@/stores/user";
import { ElNotification } from "element-plus";
import { pendoEvent } from "@/lib/helpers/pendo";
import { mapState } from "pinia";
import { ImpersonatedUser } from "@/types/Impersonation";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "DashboardImpersonateUserDialog",
  components: { vueSelect: vSelect },
  emits: ["on-impersonate", "on-cancel", "on-restore", "update:modelValue"],
  props: {
    modelValue: {
      required: true,
    },
  },
  data() {
    return {
      selectedImpersonateOption: "me",
      test: "",
      working: false,
      observer: null,
      users: [],
      selection: null,
      hasNextPage: false,
      limit: 20,
      count: 0,
      pages: 0,
      page: 0,
      query: "",
    };
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const userStore = UsersStore();
    return { userStore, router, route };
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll);
    // restore selected user from the impresonated profile if any.
    this.selection = this.impersonatedProfile ?? null;
    this.selectedImpersonateOption = this.selection ? "another" : "me";
    this.working = true;
    this.getUsers({ append: false }).finally(() => (this.working = false));
  },
  computed: {
    ...mapState(UsersStore, [
      "impersonating",
      "impersonatedProfile",
      "currentUserData",
      "isUserImpersonationEnabled",
    ]),
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    impersonatedUser() {
      return this.selection ? new ImpersonatedUser(this.selection) : null;
    },
    canContinue() {
      return (
        this.selectedImpersonateOption === "me" ||
        (this.selectedImpersonateOption === "another" &&
          this.impersonatedUser &&
          this.impersonatedUser.id != this.meId)
      );
    },
    meId() {
      return store.getters["users/userId"];
    },
  },
  methods: {
    trackInteraction(action) {
      pendoEvent(action, {
        component: "User Impersonation",
      });
    },
    fetchProfiles(query, loading) {
      if (query?.length) {
        this.doFetchProfiles(query, loading, this);
      }
    },
    doFetchProfiles: debounce((query, loading, vm) => {
      vm.query = query;
      vm.page = 0;
      vm.pages = 0;
      vm.count = 0;
      vm.getUsers({ append: false });
    }, 250),

    async getUsers({ append }) {
      try {
        const { profiles, total, totalPages } = await apiUsers.getUsersQuery({
          query: this.query,
          sortDir: "desc",
          sortBy: "created_at",
          blocked: "false",
          limit: this.limit,
          page: this.page,
        });

        if (profiles && Array.isArray(profiles)) {
          this.count = Number(total);
          this.pages = Number(totalPages);
          this.hasNextPage = this.pages > 1 && this.page < this.pages;
          // to avoid "no results" flickering effect, we need to
          // differenciate whether caller is the query search or
          // the scrolling event
          if (append) {
            this.users.push(...profiles);
          } else {
            this.users = profiles;
          }
        } else {
          this.hasNextPage = false;
          if (!append) {
            this.users = [];
          }
        }
      } catch (ex) {
        ElNotification({
          title: "Unable to load users",
          message: ex.message,
          type: "error",
        });
      }
    },

    onQuerySearch(query) {
      this.query = query;
    },
    async onContinue() {
      if (this.selectedImpersonateOption === "me") {
        this.working = true;

        // only track interactions if there's an impersonation in course
        if (this.impersonating) {
          const { id, name } = this.userStore.impersonatedProfile;
          this.trackInteraction("end_impersonation", {
            user_id: id,
            user_name: name,
          });

          // update store
          await this.userStore.removeImpersonation(this.currentUserData);
        }

        this.working = false;

        // notify close dialog
        this.$emit("on-restore");
      } else {
        this.working = true;

        // get user profile
        pubApi.profiles
          .getByUserId(this.impersonatedUser.id)
          .then(async (profile) => {
            this.trackInteraction("start_impersonation", {
              user_id: this.impersonatedUser.id,
              user_name: this.impersonatedUser.name,
            });

            // update current user with his profile
            this.impersonatedUser.setProfile(profile);

            // if is the same user, ignore the action
            if (this.impersonatedProfile?.id !== this.impersonatedUser.id) {
              // update store
              await this.userStore.impersonate(this.impersonatedUser);

              // redirect to dashboard if the current path isn't impersonable
              // make navigation synchronous: load dashboard and then
              // run the impersonation
              const currentPath = this.route.path;
              if (
                !this.userStore.impersonationAllowedPaths.some((path) =>
                  currentPath.startsWith(path)
                )
              ) {
                await this.router.push({ path: "/dashboard" });
              }

              // notify close dialog
              this.$emit("on-impersonate");
            }
          })
          .catch((ex) => {
            ElNotification({
              title: `Failed to retrieve profile for user #${this.impersonatedUser.id} - ${this.impersonatedUser.name}`,
              message: ex.message,
              type: "error",
            });
          })
          .finally(() => {
            this.working = false;
          });
      }
    },
    onCancel() {
      this.$emit("on-cancel");
    },
    async onOpen() {
      await this.$nextTick();
      this.observer.observe(this.$refs.load);
    },
    onClose() {
      this.observer.disconnect();
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        await this.$nextTick();
        this.page += 1;
        await this.getUsers({ append: true });
        ul.scrollTop = scrollTop;
      }
    },
    isFlexUser(userRoles) {
      return (
        userRoles.includes("flex_admin") || userRoles.includes("flex_viewer")
      );
    },
  },
});
</script>

<style scoped lang="scss">
.user-form :deep(.v-selection-control-group) {
  gap: 12px;
}
.v-card-text {
  min-height: 208px;
}
.vue-select-wrapper {
  z-index: 1;
}
.v-selection-control {
  :deep(.v-label) {
    font-size: 14px;
  }
}
</style>
