import Raven from "raven-js";
import Client from "./client";
import config from "../config/config";
import { checkToken } from "./tokens";

let dashboardClient;
let dashboardClientV2;

/**
 * Lazy-loaded method to get an API client at runtime
 * @return {client.ApiClient}
 */
const getClient = (authRequired = true) =>
  new Promise((resolve) => {
    if (authRequired) {
      checkToken().then(() => {
        dashboardClient = new Client.ApiClient(config.api.base_url);
        // dashboardClient = new Client.ApiClient('http://127.0.0.1:3002/api/v1/');
        resolve(dashboardClient);
      });
    } else {
      dashboardClient = new Client.ApiClient(config.api.base_url);
      // dashboardClient = new Client.ApiClient('http://127.0.0.1:3002/api/v1/');
      resolve(dashboardClient);
    }
  });

const getClientV2 = (authRequired = true) =>
  new Promise((resolve) => {
    if (authRequired) {
      checkToken().then(() => {
        dashboardClientV2 = new Client.ApiClient(config.api.base_url_v2);
        // dashboardClientV2 = new Client.ApiClient('http://127.0.0.1:3002/api/v2');
        resolve(dashboardClientV2);
      });
    } else {
      dashboardClientV2 = new Client.ApiClient(config.api.base_url_v2);
      // dashboardClientV2 = new Client.ApiClient('http://127.0.0.1:3002/api/v2');
      resolve(dashboardClientV2);
    }
  });

const captureBreadcrumb = (name, data) => {
  Raven.captureBreadcrumb({
    message: name,
    category: "API Call",
    data: {
      API: "dashboardApi",
      data,
    },
  });
};

/**
 * build params list for tile urls
 * @param {Number} siteId
 * @param {Number} accountId
 * @param {Boolean} payable
 * @param {Array<Number>} siteIds
 * @param {Boolean} historical
 * @param {Array<String>} tiles
 * @returns {String}
 */
const buildParams = ({
  siteId,
  accountId,
  payable = false,
  siteIds = null,
  historical = false,
  tiles = null,
}) => {
  const params = [];
  if (accountId) params.push(`accountId=${accountId}`);
  if (siteId) params.push(`siteId=${siteId}`);
  if (tiles !== null && Array.isArray(tiles))
    params.push(`tiles=${tiles.join(",")}`);
  if (payable) params.push("payableOnly=true");
  if (historical) params.push("historical=true");
  if (siteIds !== null && Array.isArray(siteIds))
    params.push(`siteIds=${siteIds.map(String).join(",")}`);
  return params.join("&");
};

export default {
  getProfile(data) {
    captureBreadcrumb("getProfile()", data);
    return getClient()
      .then((client) =>
        client.get(
          `dashboard/profile/${data.userId}`,
          { timeout: 10000 },
          { headers: { "Content-Type": "application/json" } }
        )
      )
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  postProfile(data) {
    captureBreadcrumb("postProfile()", data);
    return getClient()
      .then((client) =>
        client.post("dashboard/profile/", data, {
          headers: { "Content-Type": "application/json" },
        })
      )
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  putProfile(data) {
    captureBreadcrumb("putProfile()", data);
    return getClient()
      .then((client) =>
        client.put(`dashboard/profile/${data.userId}`, data, {
          headers: { "Content-Type": "application/json" },
        })
      )
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  getTile(data) {
    captureBreadcrumb("getTile()", data);
    const params = {};
    params.accountId = data.accountId;
    return getClient()
      .then((client) =>
        client.get(`dashboard/${data.agg}/${data.tiletype}/`, {
          headers: { "Content-Type": "application/json" },
          params,
        })
      )
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  getChangelog() {
    captureBreadcrumb("getChangelog");
    return getClient()
      .then((client) => client.get("dashboard/changelog"))
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  // V2
  /**
   * get overview tile data
   * @param {String} agg aggregation level
   * @param {Number} siteId if agg is SITE this is the site to get data for, otherwise 0
   * @param {Number} accountId accountId for requested data
   * @param {Boolean} payable toggle filtering for payableOnly data
   * @param {Array<Number>} siteIds
   * @param {Boolean} historical
   * @returns {Promise<Object>}
   */
  getOverviewData({
    agg,
    siteId,
    accountId,
    payable = false,
    siteIds = null,
    historical = false,
  }) {
    const params = buildParams({
      siteId,
      accountId,
      payable,
      siteIds,
      historical,
    });
    const url = `dashboard/tiles/overview/${agg}?${params}`;
    return getClientV2()
      .then((client) =>
        client.get(url, { headers: { "Content-Type": "application/json" } })
      )
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  getHistoricalOverviewData({
    agg,
    siteId,
    accountId,
    payable = false,
    siteIds = null,
  }) {
    return this.getOverviewData({
      agg,
      siteId,
      accountId,
      payable,
      siteIds,
      historical: true,
    });
  },
  /**
   * get top tile data
   * @param {String} agg
   * @param {Number} siteId
   * @param {Number} accountId
   * @param {Array<String>} tiles
   * @param {Boolean} payable
   * @param {Array<Number>} siteIds
   * @returns {Promise<Object>}
   */
  getTopDataTile({
    agg,
    siteId,
    accountId,
    tiles,
    payable = false,
    siteIds = null,
  }) {
    const params = buildParams({
      accountId,
      siteId,
      tiles,
      payable,
      siteIds,
    });
    const url = `dashboard/tiles/top/${agg}?${params}`;

    return getClientV2()
      .then((client) =>
        client.get(url, { headers: { "Content-Type": "application/json" } })
      )
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  // url is separate from other tile requests because it has a different response body
  getTopUrlTile({ agg, siteId, accountId }) {
    return getClientV2()
      .then((client) =>
        client.get(
          `dashboard/tiles/top/${agg}/url/?accountId=${accountId}&siteId=${siteId}`,
          { headers: { "Content-Type": "application/json" } }
        )
      )
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  getReferralData({ accountId }) {
    return getClientV2()
      .then((client) =>
        client.get(`dashboard/tiles/referral/account?accountId=${accountId}`, {
          headers: { "Content-Type": "application/json" },
        })
      )
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  /**
   * get blockthrough data
   * @param {String} agg
   * @param {Number} siteId
   * @param {Number} accountId
   * @param {Array<Number>} siteIds
   * @returns {Promise<Object>}
   */
  getBlockthrough({ agg, siteId, accountId, siteIds }) {
    const params = buildParams({
      siteId,
      accountId,
      siteIds,
    });
    const url = `dashboard/tiles/blockthrough/${agg}?${params}`;
    return getClientV2()
      .then((client) =>
        client.get(url, { headers: { "Content-Type": "application/json" } })
      )
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  // Messages
  getGlobalMessages() {
    captureBreadcrumb("getGlobalMessages");
    return getClient()
      .then((client) => client.get("dashboard/message/global"))
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  getMyMessages(id) {
    captureBreadcrumb("getMyMessages");
    return getClient()
      .then((client) => client.get(`dashboard/message/${id}`))
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  dismissMessage(messageId, accountId) {
    captureBreadcrumb("getMyMessages");
    return getClient()
      .then((client) =>
        client.put(
          `dashboard/message/remove/${messageId}?accountId=${accountId}`
        )
      )
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  getCpmIndex(category = "ALL") {
    captureBreadcrumb("getCpmIndex");
    return getClient()
      .then((client) => client.get(`dashboard/cpmIndex?iab=${category}`))
      .then((response) => Promise.resolve(response.data.dailyCpmIndex))
      .catch((error) => Promise.reject(error));
  },
};
