import { ref, computed } from "vue";
import { defineStore } from "pinia";
import type { IdentityProvider } from "@/types/IdentityProvider";
import { default as identityProviderService } from "@/api/identityProviders";

/**
 * IdentityProviders store
 * @link
 */
export const useIdentityProvidersStore = defineStore(
  "identityProviders",
  () => {
    const activeIdentityProvidersState = ref<IdentityProvider[]>([]);

    const activeIdentityProviders = computed<IdentityProvider[]>(
      () => activeIdentityProvidersState.value
    );

    async function getActiveIdentityProviders(): Promise<void> {
      if (activeIdentityProvidersState.value.length === 0) {
        await identityProviderService
          .getActiveIdentityProviders()
          .then((newIdentityProviders) => {
            activeIdentityProvidersState.value =
              newIdentityProviders.identityProviders;
          });
      }
    }

    return {
      activeIdentityProvidersState,
      activeIdentityProviders,
      getActiveIdentityProviders,
    };
  }
);
