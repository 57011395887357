import store from "../../store";
import intersection from "lodash/intersection";
import type {
  NavigationGuard,
  NavigationGuardNext,
  RouteLocationNormalized,
} from "vue-router";

const hasAtLeastOneRole = (roles: string[]) => {
  const userRoles = store.getters["users/roles"];
  return intersection(roles, userRoles).length > 0;
};

/**
 * Global navigation guard to check if a user is authorized
 * @see https://router.vuejs.org/en/advanced/navigation-guards.html
 */
const checkRolesBefore: NavigationGuard = (
  { meta }: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (meta && meta.roles) {
    if (hasAtLeastOneRole(meta.roles as string[])) {
      return next();
    }
    return next({ name: "dashboard" });
  }

  return next();
};

/**
 * Global Vue Router navigation guard to check authentication
 * @see https://router.vuejs.org/en/advanced/navigation-guards.html
 */
export default checkRolesBefore;
