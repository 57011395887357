import auth0 from "auth0-js";
import config from "../config/config";

const webAuth = new auth0.WebAuth({
  domain: config.auth0.domain,
  clientID: config.auth0.client_id,
  audience: config.auth0.audience,
  responseType: "token id_token",
  scope: "openid app_metadata profile",
  callbackOnLocationHash: true,
});

/**
 * attempt to send a password reset email to the email entered
 * @param email
 * @returns {Promise<unknown>}
 */
function sendResetEmail(email) {
  return new Promise((resolve, reject) => {
    webAuth.changePassword(
      {
        connection: config.auth0.connection,
        email,
      },
      (err, res) => {
        if (err) {
          reject(err);
        } else {
          resolve(res);
        }
      }
    );
  });
}

export { webAuth as default, sendResetEmail };
