import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import config from "../config/config";
import users from "./modules/users";
import accounts from "./modules/accounts";
import networks from "./modules/networks";
import placements from "./modules/placements";
import profiles from "./modules/profiles";
import notifications from "./modules/notifications";
import ui from "./modules/ui";
import tiles from "./modules/tiles";
import reports from "./modules/reports";
import fadSitesAmp from "./modules/fadSitesAmp";
import adBlockRecovery from "./modules/adBlockRecovery";

const debug = config.app.env !== "production";

/**
 * Vuex store
 * @see https://vuex.vuejs.org/
 */
export default createStore({
  modules: {
    accounts,
    networks,
    placements,
    profiles,
    reports,
    tiles,
    users,
    notifications,
    ui,
    fadSitesAmp,
    adBlockRecovery,
  },
  strict: debug,
  plugins: [
    createPersistedState({
      paths: [
        "accounts.accountName",
        "users.accessToken",
        "users.idToken",
        "users.profile",
        "users.username",
        "ui.adBlockWarningDismissed",
        "ui.navBarOpen",
      ],
    }),
    createPersistedState({
      storage: window.sessionStorage,
      paths: [
        "accounts.activeDomain",
        "accounts.sitesForAccount",
        "users.impersonation_accounts",
        "users.impersonation",
      ],
    }),
  ],
});
