import config from "@/config/config";

declare let gtag: Function;

export default function gtagEvent(
  action: string,
  {
    component,
    user_id,
    account_name,
    user_type,
    report_type,
    inventory_type,
  }: {
    component: string;
    user_id: number;
    account_name: string;
    user_type: string;
    report_type?: string;
    inventory_type?: string;
  }
) {
  if (gtag && config.app.env === "prod") {
    gtag("event", action, {
      component,
      user_id,
      account_name,
      user_type,
      report_type,
      inventory_type,
    });
  } else {
    console.warn("Google Analytics not loaded, not sending event");
  }
}
