import type { App } from "vue";
import mitt from "mitt";

/**
 * Register event bus. Replacement of vue 2 event emitter
 */
export default {
  install(app: App) {
    app.provide("$emitter", mitt());
  },
};
