/* eslint-disable no-shadow,no-param-reassign */
import merge from "lodash/merge";
import * as types from "./mutation-types";
import accountsApi from "../../../api/accounts";
import type Site from "@/types/Site";
import type PublisherAccount from "@/types/PublisherAccount";
import type { Commit } from "vuex";
import { flexAccountService } from "@/api/flex";
import type { FlexSite } from "@/types/Flex";
import { SWITCHING_ACCOUNT } from "./mutation-types";

interface State {
  items: PublisherAccount[];
  sites: Site[];
  flexSitesForAccount: FlexSite[];
  sitesForAccount: Site[];
  allAccountsLoaded: boolean;
  activeSiteIds: number[];
  accountName: string | null;
  reportSiteFilterValues: number[];
  savedReport: object | null;
  reportName: string | null;
  metadataOptions: object;
  reportsHidden: boolean;
  staffSiteId: string | null;
  reportTypes: any[];
  switchingAcccount: boolean;
}

const state: State = {
  items: [],
  sites: [],
  sitesForAccount: [],
  flexSitesForAccount: [],
  allAccountsLoaded: false,
  activeSiteIds: [],
  accountName: null,
  reportSiteFilterValues: [],
  savedReport: null,
  reportName: null,
  metadataOptions: {},
  reportsHidden: false,
  staffSiteId: null,
  reportTypes: [],
  switchingAcccount: false,
};

const getters = {
  items: (state: State) => state.items,
  sites: (state: State) => state.sites,
  siteIds: (state: State) => state.activeSiteIds,
  allAccountsLoaded: (state: State) => state.allAccountsLoaded,
  accountName: (state: State) => state.accountName,
  all: (state: State) => state.items,
  sitesForAccount: (state: State) => state.sitesForAccount,
  flexSitesForAccount: (state: State) => state.flexSitesForAccount,
  activeSitesForAccount: (state: State) =>
    state.sitesForAccount.filter((s: Site) => !s.inactive),
  staffSiteId: (state: State) => state.staffSiteId,
  siteForAccountById: (state: State) => (id: Number) =>
    state.sitesForAccount.find((site) => site.id === id),
  flexSiteForAccountById: (state: State) => (id: Number) =>
    state.flexSitesForAccount.find((site) => site.id === id),
  reportTypesForAccount: (state: State) => state.reportTypes,
  switchingAcccount: (state: State) => state.switchingAcccount,
};

const actions = {
  getAll({ commit }: { commit: Commit }) {
    return accountsApi
      .getAccounts()
      .then((response: { accounts: PublisherAccount[] }) => {
        commit(types.RECEIVE_ACCOUNTS, response.accounts);
        commit(types.ALL_LOADED, true);
      });
  },
  getItem({ commit }: { commit: Commit }, id: number) {
    if (state.items[id]) {
      return Promise.resolve(state.items[id]);
    }

    return new Promise((resolve, reject) => {
      accountsApi
        .get(id)
        .then((response: PublisherAccount) => {
          commit(types.RECEIVE_ACCOUNT, { id: response.id, data: response });
          commit(types.RECEIVE_ACCOUNT_NAME, response.name);
          resolve(response);
        })
        .catch((err: Error) => {
          reject(err);
        });
    });
  },
  getAccountName({ commit }: { commit: Commit }, id: number) {
    return new Promise((resolve, reject) => {
      accountsApi
        .getName(id)
        .then((response: Pick<PublisherAccount, "name">) => {
          commit(types.RECEIVE_ACCOUNT_NAME, response.name);
          resolve(response);
        })
        .catch((err: Error) => {
          reject(err);
        });
    });
  },
  getSitesForAccount({ commit }: { commit: Commit }, accountId: number) {
    return accountsApi
      .getSitesForAccount(accountId)
      .then((payload: { data: { sites: Site[] } }) => {
        commit(types.RECEIVE_SITES_FOR_ACCOUNT, payload.data.sites);
        return Promise.resolve({ accountId, sites: payload.data.sites });
      })
      .catch((err: Error) => Promise.reject(err.message));
  },
  getFlexSitesForAccount({ commit }: { commit: Commit }, accountId: number) {
    return flexAccountService
      .getSitesByAccount(accountId)
      .then((payload: any) => {
        commit(types.RECEIVE_FLEX_SITES_FOR_ACCOUNT, payload.data.sites);
        return Promise.resolve({ accountId, sites: payload.data.sites });
      })
      .catch((err: Error) => Promise.reject(err.message));
  },
  getAllSites({ commit }: { commit: Commit }) {
    accountsApi.getAllSites().then((response: { data: { sites: Site[] } }) => {
      commit(types.RECEIVE_SITES, response.data.sites);
    });
  },
  getActiveSites({ commit }: { commit: Commit }) {
    accountsApi
      .getActiveSites()
      .then((response: { data: { sites: Site[] } }) => {
        commit(types.RECEIVE_SITES, response.data.sites);
      });
  },
  getReportTypes({ commit }: { commit: Commit }, accountId: number) {
    accountsApi.getReportTypes(accountId).then((reportTypes: any[]) => {
      commit(types.RECEIVE_REPORT_TYPES, reportTypes);
    });
  },
  setActiveSiteIds({ commit }: { commit: Commit }, activeSiteIds: number[]) {
    commit(types.SET_ACTIVE_SITE_IDS, activeSiteIds);
    // commit(types.SET_REPORT_SITE_FILTER_VALUES, [activeSiteIds]);
  },
};

const mutations = {
  [types.RECEIVE_ACCOUNTS](state: State, accounts: PublisherAccount[]) {
    state.items = accounts;
  },
  [types.RECEIVE_ACCOUNT](
    state: State,
    { id, data }: { id: number; data: PublisherAccount }
  ) {
    if (state.items[id]) {
      merge(state.items[id], data);
    } else {
      state.items[id] = data;
    }
  },
  [types.RECEIVE_ACCOUNT_NAME](state: State, accountName: string) {
    state.accountName = accountName;
  },
  [types.RECEIVE_SITES](state: State, sites: Site[] = []) {
    state.sites = sites;
  },
  [types.RECEIVE_REPORT_TYPES](state: State, reportTypes: any[] = []) {
    state.reportTypes = reportTypes;
  },
  [types.RECEIVE_SITES_FOR_ACCOUNT](state: State, sites: Site[] = []) {
    state.sitesForAccount = sites;
  },
  [types.RECEIVE_FLEX_SITES_FOR_ACCOUNT](state: State, sites: FlexSite[] = []) {
    state.flexSitesForAccount = sites;
  },
  [types.ALL_LOADED](state: State, loaded: boolean) {
    state.allAccountsLoaded = loaded;
  },
  [types.SET_ACTIVE_SITE_IDS](state: State, siteIds: number[]) {
    state.activeSiteIds = siteIds;
  },
  [types.SWITCHING_ACCOUNT](state: State, inProgress: boolean) {
    state.switchingAcccount = inProgress;
  },
};

/**
 * The accounts Vuex module
 * @see https://vuex.vuejs.org/en/modules.html
 */
export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
