import config from "../config/config";
import AbstractRestService from "@/api/abstractRestService";
import type { IdentityProviderResponse } from "@/types/IdentityProvider";

export interface IdentityProviderService {
  /**
   * Get all active Identity Providers.
   */
  getActiveIdentityProviders(): Promise<IdentityProviderResponse>;
}

/**
 * HTTP Identity Provider Service implementation
 *
 * @implements {IdentityProviderService}
 */
export class IdentityProviderRestImpl
  extends AbstractRestService
  implements IdentityProviderService
{
  /**
   * Init
   * @param authRequired
   */
  constructor(authRequired?: boolean) {
    super(config.api.base_url, authRequired);
    // super("http://127.0.0.1:3003/api/v1", authRequired);
  }

  /**
   *
   */
  getActiveIdentityProviders(): Promise<IdentityProviderResponse> {
    return this.getRestClient()
      .then((client) => client.get("identity-providers"))
      .then((response) => Promise.resolve(response.data))
      .catch((error: any) => Promise.reject(error));
  }
}

export default new IdentityProviderRestImpl();
