import config from "@/config/config";
import * as configcat from "configcat-js";
import store from "@/store";

const configCatClient = configcat.getClient(
  config.configcat.sdk_key, // <-- This is the actual SDK Key for your Test environment
  configcat.PollingMode.AutoPoll,
  {}
);

async function getBooleanValue(flag: string): Promise<boolean> {
  const value: boolean = await configCatClient.getValueAsync(flag, false);
  return value;
}

async function getBooleanValueByEmail(flag: string): Promise<boolean> {
  const userId = store.getters["users/userId"];
  const email = store.getters["users/email"];
  const configCatUser = new configcat.User(userId, email);
  const value: boolean = await configCatClient.getValueAsync(
    flag,
    false,
    configCatUser
  );
  return value;
}

async function getBooleanValueWithUserRoles(flag: string): Promise<boolean> {
  const roles = await store.getters["users/roles"];

  // set ConfigCat user.identifier as a comma-separated string of the user's roles
  const userWithRoles = new configcat.User(roles.join(","));

  const value: boolean = await configCatClient.getValueAsync(
    flag,
    false,
    userWithRoles
  );
  return value;
}

async function getStringValueWithUserRoles(flag: string): Promise<string> {
  const roles = await store.getters["users/roles"];

  // set ConfigCat user.identifier as a comma-separated string of the user's roles
  const userWithRoles = new configcat.User(roles.join(","));

  const value: string = await configCatClient.getValueAsync(
    flag,
    "AddChartLegacy",
    userWithRoles
  );
  return value;
}

export default {
  getBooleanValue,
  getBooleanValueByEmail,
  getBooleanValueWithUserRoles,
  getStringValueWithUserRoles,
};
