import type { FlexPagination, FlexUser } from "@/types/Flex";
import config from "../../config/config";
import { sendResetEmail } from "../auth0";
import AbstractRestService from "@/api/abstractRestService";

/**
 * Flex operations
 */
export interface FlexUserService<T, P> {
  /**
   * Get all flex users.
   */
  getUsers(params: any): Promise<P>;

  /**
   * Create flex user
   *
   * @param user
   */
  createUser(user: T): Promise<T>;

  /**
   * Update flex user
   *
   * @param user
   */
  updateUser(user: T): Promise<T>;

  /**
   * Delete flex user
   *
   * @param user
   */
  deleteUser(user: T): Promise<void>;

  /**
   * Send reset password email
   */
  resetPassword(user: T): Promise<unknown>;
}

/**
 * HTTP Flex service implementation
 *
 * @implements {FlexUserService}
 */
export class FlexUserServiceRestImpl
  extends AbstractRestService
  implements FlexUserService<FlexUser, FlexPagination>
{
  /**
   * Init
   * @param authRequired
   */
  constructor(authRequired?: boolean) {
    super(config.api.base_url_v3, authRequired);
  }

  /**
   * @param params
   */
  getUsers(params: any): Promise<FlexPagination> {
    return this.getRestClient()
      .then((client) => client.get("/authorization/flex/users", { params }))
      .then((response: any) => Promise.resolve(response.data as FlexPagination))
      .catch((error: any) => Promise.reject(error));
  }

  /**
   * @param user
   */
  createUser(user: FlexUser): Promise<FlexUser> {
    return this.getRestClient()
      .then((client) => client.post("/authorization/flex/users", user))
      .then((response: any) => Promise.resolve(response.data))
      .catch((error: any) => Promise.reject(error));
  }

  /**
   * @param user
   */
  updateUser(user: FlexUser): Promise<FlexUser> {
    return this.getRestClient()
      .then((client) =>
        client.put(`/authorization/flex/users/${user.id}`, user)
      )
      .then((response: any) => Promise.resolve(response.data))
      .catch((error: any) => Promise.reject(error));
  }

  /**
   * @param user
   */
  deleteUser(user: FlexUser): Promise<void> {
    return this.getRestClient()
      .then((client) => client.delete(`/authorization/flex/users/${user.id}`))
      .then(() => Promise.resolve())
      .catch((error: any) => Promise.reject(error));
  }

  /**
   * @param user
   */
  resetPassword(user: FlexUser): Promise<unknown> {
    return sendResetEmail(user.email);
  }
}

export default new FlexUserServiceRestImpl();
