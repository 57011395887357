import type {
  PrebidIdentityProvider,
  PrebidNetwork,
} from "@/types/PrebidSiteConfig";

export type AbTest = {
  id: string;
  accountId: number;
  siteId: number;
  collectionId: string;
  control: {
    deploymentId: string;
    trafficPercentage: number;
  };
  testItems: Array<{
    deploymentId: string;
    pubfigTemplateId: string | null;
    gitHash: string;
    pubfigConfigVersion: number;
    pubfigConfigId: string;
    trafficPercentage: number;
  }>;
  name: string;
  hypothesis: string;
  methodology: string;
  startDate: string; // ISO 8601 date format
  started: boolean;
  endDate: string; // ISO 8601 date format
  createdBy: string;
  percentageOfTotalTraffic: number;
  type: string;
  earlyTermination: string | null;
  createdAt: string; // ISO 8601 date format with time
  updatedAt: string; // ISO 8601 date format with time
};

export type GetAbTestsResponse = {
  abTests: AbTest[];
  total: number;
  totalPages: number;
  page: number;
  limit: number;
  query: string;
  sortBy: string;
  sortDir: string;
};

export enum AbTestStatus {
  Running = "Running",
  Scheduled = "Scheduled",
  Cancelled = "Cancelled",
  Completed = "Completed",
}

export enum TestGroup {
  Control = "Control",
  TreatmentA = "Treatment A",
}

export interface AdStack {
  id: string;
  name: string;
  description: string;
  refreshRate: number;
  mobileTimeout: number;
  desktopTimeout: number;
  configVersion: number;
  testGroup: TestGroup;
  networks: Array<PrebidNetwork>;
  identityProviders: Array<PrebidIdentityProvider>;
}

export type AbTestView = {
  id: string;
  name: string;
  description: string;
  siteId: number;
  siteDomain: string;
  startDate: Date;
  endDate: Date;
  daysRemaining: number;
  createdBy: string;
  status: AbTestStatus;
  adStacks: Array<AdStack>;
  percentageOfTotalTraffic: number;
};

export interface AdStackPerformanceSummary {
  adStackId: string;
  cpm: number;
  revenue: number;
  impressions: number;
  isWinner?: boolean;
}

export interface PerformanceItem {
  testGroupName: string;
  revenue: number;
  impressions: number;
  cpm: number;
}

export interface DailyPerformance extends PerformanceItem {
  date: string;
}

export interface AdUnitPerformance extends PerformanceItem {
  adUnitName: string;
}

export interface DemandPerformance extends PerformanceItem {
  partnerName: string;
}

export interface PerformanceSummaryItem {
  revenue: number;
  impressions: number;
  cpm: number;
  isWinner?: boolean;
  testGroup: TestGroup;
}

export type PerformanceSummary = PerformanceSummaryItem[];
