import type User from "@/types/User";
import type Profile from "@/types/Profile";

export type CurrentUserData = { accountId: number; userId: number };

export interface PubdashUser {
  id: number;
  name: string;
  email: string;
  accountId: number;
  profile: Profile | null;
  roles: string[];
  setProfile(profile: Profile): void;
}

export class ImpersonatedUser implements PubdashUser {
  id: number = 0;
  email: string = "";
  name: string = "";
  profile: Profile | null = null;
  accountId: number = 0;
  roles: string[] = [];

  constructor(user: User) {
    this.id = user.id;
    this.name = user.name;
    this.email = user.email;
    this.accountId = user.defaultAccountId;
    this.roles = user.roles!.map((role) => role.name);
  }

  setProfile(profile: Profile): void {
    this.profile = profile;
  }

  get isFlex(): boolean {
    if (this.roles) {
      return (
        this.roles.includes("flex_admin") || this.roles.includes("flex_viewer")
      );
    } else {
      return false;
    }
  }
  get isAdmin(): boolean {
    if (this.roles) {
      return this.roles.includes("administrator");
    } else {
      return false;
    }
  }
}
