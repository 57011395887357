<template>
  <slot v-if="!hasModuleError" />
  <OutageScreen v-else />
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import { shallowRef } from "vue";
import { OutageScreen } from "@/components/mantainance";
import sentry from "@/lib/helpers/sentry";

interface IError extends Error {
  message: string;
}

const router = useRouter();

const hasModuleError = shallowRef<boolean>(false);

const dynamicModuleError: readonly string[] = [
  "Failed to fetch dynamically imported module",
  "Failed to load module script",
];

const logSentryEvent = (error: Error) => {
  sentry.captureBreadcrumb(
    "Vite",
    error.message,
    "dynamic_import",
    {},
    "error"
  );
  sentry.setTag("error_type", "dynamic_import");
  sentry.captureException(error);
};

const handleErrorRouter = (error: IError) => {
  if (dynamicModuleError.some((err) => error.message.includes(err))) {
    hasModuleError.value = true;
    logSentryEvent(error);
    return;
  }
  throw error;
};

// Adds an error handler that is called every time a non caught error
// happens during navigation.
// https://router.vuejs.org/api/interfaces/Router.html#onError-
router.onError(handleErrorRouter);
</script>
