import config from "../../config/config";
import AbstractRestService from "@/api/abstractRestService";
import type {
  NetworkRequest,
  NetworkRequestCustom,
  NetworkRequestNonCustom,
} from "@/types/NetworkRequest";

export interface NetworkRequestService {
  getNetworkRequestsBySiteIds(siteIds: number[]): Promise<NetworkRequest[]>;
  createNonCustomDemandRequest(data: NetworkRequestNonCustom): Promise<void>;
  createCustomDemandRequest(data: NetworkRequestCustom): Promise<void>;
}

export class NetworkRequestServiceRestImpl
  extends AbstractRestService
  implements NetworkRequestService
{
  constructor(authRequired?: boolean) {
    super(config.api.base_url, authRequired);
    // super("http://127.0.0.1:3003/api/latest", authRequired);
  }

  async getNetworkRequestsBySiteIds(
    siteIds: number[]
  ): Promise<NetworkRequest[]> {
    return this.getRestClient()
      .then((client) => client.post("/networks/requests/search", siteIds))
      .then((response: any) => Promise.resolve(response.data))
      .catch((error: any) => Promise.reject(error));
  }

  async createNonCustomDemandRequest(
    data: NetworkRequestNonCustom
  ): Promise<void> {
    return this.getRestClient()
      .then((client) => client.post("/networks/requests", data))
      .catch((error: any) => Promise.reject(error));
  }

  async createCustomDemandRequest(data: NetworkRequestCustom): Promise<void> {
    return this.getRestClient()
      .then((client) => client.post("/networks/requests/custom", data))
      .catch((error: any) => Promise.reject(error));
  }
}

export default new NetworkRequestServiceRestImpl();
