import type { App } from "vue";
import config from "@/config/config";

export type ZendeskPluginOptions = { id: string; src: string };

export type Zendesk = {
  load: () => Promise<unknown>;

  login: (token: string) => void;

  logout: () => void;

  show: () => void;

  hide: () => void;

  execIfExist: (
    target: string,
    cmd: string,
    ...callbacks: ((...args: any[]) => void)[]
  ) => void;
};

declare global {
  // eslint-disable-next-line no-var
  var zE: any;
}

/**
 * Zendesk vue plugin
 */
export default {
  install: async function ZendeskPlugin(
    app: App,
    options: ZendeskPluginOptions
  ) {
    const { id, src } = options;

    // zendesk proxy object
    const $zendesk: Zendesk = {
      /**
       * Load script
       */
      async load() {
        const url = new URL(options.src);
        url.searchParams.append("key", config.zendesk.app_key);

        return new Promise((resolve, reject) => {
          const scriptEl = document.createElement("script");
          scriptEl.id = options.id;
          scriptEl.src = url.href;
          scriptEl.addEventListener("load", resolve);
          scriptEl.addEventListener("error", reject);
          document.body.append(scriptEl);
        });
      },

      login(token: string) {
        this.execIfExist(
          "messenger",
          "loginUser",
          function jwtCallback(callback: (arg0: string) => void) {
            callback(token);
          },
          function loginCallback(error: {
            type: any;
            reason: any;
            message: any;
          }) {
            if (error) {
              // Example error handling
              const { type, reason, message } = error;
              console.error(
                `Error Type: ${type}, Reason: ${reason}, Message: ${message}`
              );
            }
          }
        );
      },

      logout() {
        this.execIfExist("messenger", "logoutUser");
      },

      show() {
        this.execIfExist("messenger", "show");
      },

      hide() {
        this.execIfExist("messenger", "hide");
      },

      execIfExist(
        target: string,
        cmd: string,
        ...callbacks: ((...args: any[]) => void)[]
      ) {
        if (window.zE) {
          window.zE.call(window, target, cmd, ...callbacks);
        } else {
          console.error(new Error("Must load Zendesk script first!"));
        }
      },
    };

    app.provide("$zendesk", $zendesk);
  },
};
