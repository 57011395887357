/* eslint-disable no-shadow,no-param-reassign */
import * as types from "./mutation-types";
import dashboardAPI from "../../../api/dashboard";
import moment from "moment";

const state = {
  myMessages: [],
  globalMessages: [],
};

const getters = {
  myMessages: (state) => state.myMessages,
  globalMessages: (state) => state.globalMessages,
};

const actions = {
  getMyMessages({ commit }, id) {
    return new Promise((resolve, reject) => {
      dashboardAPI
        .getMyMessages(id)
        .then((res) => {
          const filtered = res.dashboardMessages
            ? res.dashboardMessages.filter((msg) => {
                if (!msg.active) return false;
                else if (
                  msg.expires &&
                  moment(msg.expires.slice(0, 10)).isSameOrBefore(moment())
                )
                  return false;
                return true;
              })
            : [];
          commit(types.RECEIVE_MY_MESSAGES, { messages: filtered });
          resolve(filtered);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getGlobalMessages({ commit }) {
    return new Promise((resolve, reject) => {
      dashboardAPI
        .getGlobalMessages()
        .then((res) => {
          const filtered = res.dashboardMessages
            ? res.dashboardMessages.filter((msg) => {
                if (!msg.active) return false;
                else if (
                  msg.expires &&
                  moment(msg.expires.slice(0, 10)).isSameOrBefore(moment())
                )
                  return false;
                return true;
              })
            : [];
          commit(types.RECEIVE_GLOBAL_MESSAGES, { messages: filtered });
          resolve(filtered);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  dismissMessage({ commit }, data) {
    commit(types.DISMISS_MESSAGE, { index: data.index });
    return new Promise((resolve, reject) => {
      dashboardAPI
        .dismissMessage(data.messageId, data.accountId)
        .then(() => {
          resolve();
        })
        .catch((err) => reject(err));
    });
  },
};

const mutations = {
  [types.RECEIVE_MY_MESSAGES](state, { messages }) {
    state.myMessages = messages;
  },
  [types.RECEIVE_GLOBAL_MESSAGES](state, { messages }) {
    state.globalMessages = messages;
  },
  [types.DISMISS_MESSAGE](state, { index }) {
    state.myMessages.splice(index, 1);
  },
};

/**
 * The accounts Vuex module
 * @see https://vuex.vuejs.org/en/modules.html
 */
export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
