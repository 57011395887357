import type { Commit, Dispatch } from "vuex";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import type Profile from "@/types/Profile";
import pubApi from "@/api/pub";
import * as types from "./mutation-types";

dayjs.extend(utc);
dayjs.extend(timezone);

interface State {
  profile: null | Profile;
  isProfileLoaded: boolean;
  impersonation: null | Profile;
  previousProfile: null | Profile;
}

const state: State = {
  profile: null,
  isProfileLoaded: false,
  impersonation: null,
  previousProfile: null,
};

const getters = {
  profile: (state: State) => state.profile,
  impersonation: (state: State) => state.impersonation,
  previousProfile: (state: State) => state.previousProfile,
  lastActiveEnvironment: (state: State) =>
    state.profile?.lastActiveEnvironment || "WEB",
  isProfileLoaded: (state: State) => state.isProfileLoaded,
};

const actions = {
  async fetchAllProfiles({
    commit,
    dispatch,
  }: {
    commit: Commit;
    dispatch: Dispatch;
  }) {
    return await pubApi.profiles.getAll();
  },

  async fetchProfile(
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    {
      userId,
      lastActiveEnvironment = "WEB",
    }: { userId: number; lastActiveEnvironment?: string }
  ) {
    commit(types.SET_IS_PROFILE_LOADED, false);

    try {
      const profile = await pubApi.profiles.getByUserId(userId);

      // set default last environment. If the previous enviroment does not
      // match with any of the supported tabs, then use TOTAL as the
      // default one to avoid a white screen. This is happening when
      // a flex role was removed. Surely it can be improved in the backend.
      // https://freestar.atlassian.net/browse/BITOPS-8534
      if (
        !["TOTAL", "WEB", "AMP", "APP", "OTHERS"].includes(
          profile.lastActiveEnvironment
        )
      ) {
        profile.lastActiveEnvironment = "WEB";
      }

      commit(types.RECEIVE_PROFILE, profile);
    } catch (err: any) {
      if (err.response.status === 404) {
        const userTimezone = dayjs.tz.guess();
        const profile: Pick<
          Profile,
          "userId" | "timezone" | "lastActiveEnvironment"
        > = {
          userId,
          timezone: userTimezone,
          lastActiveEnvironment,
        };

        return dispatch("createProfile", { userId, profile });
      }
      throw new Error(err.response);
    } finally {
      commit(types.SET_IS_PROFILE_LOADED, true);
    }
  },

  async fetchFlexProfile(
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    {
      userId,
      lastActiveEnvironment = "FLEX_PREBID",
    }: { userId: number; lastActiveEnvironment?: string }
  ) {
    commit(types.SET_IS_PROFILE_LOADED, false);

    try {
      const profile = await pubApi.profiles.getByUserId(userId);

      // Same as fetchProfile except that a flex role
      // was added to a non-flex user.
      // https://freestar.atlassian.net/browse/BITOPS-8534
      if (!["FLEX_PREBID"].includes(profile.lastActiveEnvironment)) {
        profile.lastActiveEnvironment = "FLEX_PREBID";
      }

      commit(types.RECEIVE_PROFILE, profile);
    } catch (err: any) {
      if (err.response.status === 404) {
        const userTimezone = dayjs.tz.guess();
        const profile: Pick<
          Profile,
          "userId" | "timezone" | "lastActiveEnvironment"
        > = {
          userId,
          timezone: userTimezone,
          lastActiveEnvironment,
        };

        return dispatch("createProfile", { userId, profile });
      }
      throw new Error(err.response);
    } finally {
      commit(types.SET_IS_PROFILE_LOADED, true);
    }
  },

  async createProfile(
    { commit }: { commit: Commit },
    { userId, profile }: { userId: number; profile: Profile }
  ) {
    commit(types.SET_IS_PROFILE_LOADED, false);
    const createdProfile = await pubApi.profiles.create(userId, profile);
    commit(types.RECEIVE_PROFILE, createdProfile);
    commit(types.SET_IS_PROFILE_LOADED, true);
  },

  async updateLastActiveEnvironment(
    { commit }: { commit: Commit },
    { env, userId }: { env: string; userId: number }
  ) {
    if (state.profile) {
      const profileUpdatePayload = {
        ...state.profile,
        lastActiveEnvironment: env,
      };
      commit(types.RECEIVE_PROFILE, profileUpdatePayload);
      const profile = await pubApi.profiles.updateByUserId(
        userId,
        profileUpdatePayload
      );
      commit(types.RECEIVE_PROFILE, profile);
    }
  },
};

const mutations = {
  [types.RECEIVE_PROFILE](state: State, profile: Profile) {
    state.profile = profile;
  },
  [types.UPDATE_PROFILE](state: State, profile: Profile) {
    state.profile = profile;
  },
  [types.SET_IS_PROFILE_LOADED](state: State, isProfileLoaded: boolean) {
    state.isProfileLoaded = isProfileLoaded;
  },
  [types.SET_IMPERSONATION](state: State, profile: Profile) {
    state.impersonation = profile;
  },
  [types.CLEAR_IMPERSONATION](state: State) {
    state.impersonation = null;
    state.profile = state.previousProfile;
  },
  [types.SET_PREVIOUS_PROFILE](state: State, profile: Profile) {
    state.previousProfile = profile;
  },
};

/**
 * @see https://vuex.vuejs.org/en/modules.html
 */
export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
